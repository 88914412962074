

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from "../../components/sidebar/Sidebar";
import BASE_URL from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./bookdata.scss";


const Book = () => {
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [updatedQuantity, setUpdatedQuantity] = useState('');
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [showAddBookPopup, setShowAddBookPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newBookData, setNewBookData] = useState({
    title: '',
    author: '',
    quantity: '',
    price: '',
    image: '',
    year: '',
  });
  const [expandedTitle, setExpandedTitle] = useState({});
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [selectedBook, setSelectedBook] = useState(null); // Define selectedBook state

  useEffect(() => {
    fetchBooks();
  }, []);

  const fetchBooks = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const response = await axios.get(`${BASE_URL}/dashboard/get`, {
        headers: {
          'Content-Type': 'application/json',
          'Auth': `Bearer ${key}`,
        },
      });
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching book details:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  };

  const toggleExpandedTitle = (bookId) => {
    setExpandedTitle((prevState) => ({
      ...prevState,
      [bookId]: !prevState[bookId],
    }));
  };

  const handleUpdateClick = (book) => {
    setSelectedBook(book); // Select the book
    setUpdatedQuantity(book.quantity);
    setUpdatedPrice(book.price);
  };

  const handleUpdate = async () => {
    try {
      const updatedBook = { ...selectedBook, quantity: updatedQuantity, price: updatedPrice };
      await axios.put(`${BASE_URL}/api/booktables/${selectedBook.id}`, updatedBook);
      console.log("Book updated successfully");
      fetchBooks();
    } catch (error) {
      console.error('Error updating book:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBookData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewBookData((prevData) => ({
      ...prevData,
      image: file,
    }));
  };

  const handleAddBookSubmit = async () => {
    try {
      const key = localStorage.getItem('jwtToken');
      const headers = {
        'Auth': `Bearer ${key}`,
        'Content-Type': 'multipart/form-data',
      };
  
      const formData = new FormData();
      for (const key in newBookData) {
        formData.append(key, newBookData[key]);
      }
  
      if (newBookData.image.type !== 'image/png') {
        setErrorMessage('Invalid image file type. Only PNG images are allowed.');
        return;
      }
  
      const response = await axios.post(`${BASE_URL}/dashboard/add`, formData, { headers });
  
      if (response.status === 201) {
        console.log("New book added");
        fetchBooks();
      } else {
        console.error('Error adding new book. Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error adding new book:', error);
    }
  
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const filteredBooks = books.books ? books.books.filter((book) =>
    book && book.title && book.title.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  return (
    <div style={{ display: "flex" }}>
      <RedirectComponent />
      <div className="scrollableSidebar">
        <Sidebar />
      </div>
      <div className="book-page">
        <h2 style={{ textAlign: "center" }}>Book Details</h2>
        <input
          type="text"
          placeholder="Search by title"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="book-input"
        />
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <Link to="/orderbook" style={{ textDecoration: "none" }}>
            <button className="book-button">Ordered Books</button>
          </Link>
          <button className="book-button" onClick={() => setShowAddBookPopup(true)}>Add New Book</button>
        </div>
        <div className="book-container">
          {filteredBooks.map((book, index) => (
            <div key={book.id} className="book-card">
              <div className="book-details">
                <div className="book-title" onClick={() => toggleExpandedTitle(book.id)}>{expandedTitle[book.id] ? book.title : book.title.substring(0, 20)}</div>
                <div className="book-image">
                  <img
                    src={`${BASE_URL}${book.imageUrl}`}
                    alt={book.title}
                    className="book-image"
                  />
                </div>
  

                <p>Author: {book.author}</p>
                <p>Year: {book.year}</p>
                <p>Quantity: {book.quantity}</p>
                <p>Price: ₹ {book.price}</p>
                <p>Ratings: {parseFloat(book.ratings).toFixed(1)} <span>&#9733;</span></p>
                <button className="book-button" onClick={() => handleUpdateClick(book)}>Update</button>
                {selectedBook && selectedBook.id === book.id && (
                  <div className="book-update-form">
                    <input
                      type="number"
                      placeholder="Enter new quantity"
                      value={updatedQuantity}
                      onChange={(e) => setUpdatedQuantity(e.target.value)}
                      className="book-update-input"
                    />
                    <input
                      type="number"
                      placeholder="Enter new price"
                      value={updatedPrice}
                      onChange={(e) => setUpdatedPrice(e.target.value)}
                      className="book-update-input"
                    />
                    <button className="book-button" onClick={handleUpdate}>Save Changes</button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {showAddBookPopup && (
          <div className="book-popup">
            <div className="book-popup-content">
              <h2 className="book-popup-header">Add New Book</h2>
              {errorMessage && (
                <div className="book-error">{errorMessage}</div>
              )}
              <form className="book-form">
                <div className="book-form-group">
                  <label>Title:</label>
                  <input type="text" name="title" value={newBookData.title} onChange={handleInputChange} className="book-input" />
                </div>
                <div className="book-form-group">
                  <label>Author:</label>
                  <input type="text" name="author" value={newBookData.author} onChange={handleInputChange} className="book-input" />
                </div>
                <div className="book-form-group">
                  <label>Quantity:</label>
                  <input type="number" name="quantity" value={newBookData.quantity} onChange={handleInputChange} className="book-input" />
                </div>
                <div className="book-form-group">
                  <label>Year:</label>
                  <input type="text" name="year" value={newBookData.year} onChange={handleInputChange} className="book-input" />
                </div>
                <div className="book-form-group">
                  <label>Price:</label>
                  <input type="text" name="price" value={newBookData.price} onChange={handleInputChange} className="book-input" />
                </div>
                <div className="book-form-group">
                  <label>Image:</label>
                  <input type="file" name="image" accept="image/*" onChange={handleFileChange} className="book-input" />
                </div>
                <div className="book-form-buttons">
                  <button className="book-button" type="button" onClick={handleAddBookSubmit}>Add</button>
                  <button className="book-button" type="button" onClick={() => setShowAddBookPopup(false)}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Book;
