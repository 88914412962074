
// import React, { useState, useEffect, useContext, useRef } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { Switch } from '@mui/material';
 import BASE_URL from '../config/apiConfig';
// import MessageIcon from '@mui/icons-material/Message';
// import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
// import LogoutIcon from "@mui/icons-material/Logout";
// import { DarkModeContext } from "../../context/darkModeContext";

// const Navbar = () => {
//   const { dispatch } = useContext(DarkModeContext);
//   const history = useNavigate();
//   const notificationRef = useRef(null);
//   const messageRef = useRef(null);
//   const [showNotification, setShowNotification] = useState(false);
//   const [notificationData, setNotificationData] = useState([]);
//   const [messageData, setMessageData] = useState([]);
//   const [messageCount, setMessageCount] = useState(0);
//   const [showMessageBox, setShowMessageBox] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedNotification, setSelectedNotification] = useState(null);
//   const [isHovered, setIsHovered] = useState(false);

//   useEffect(() => {
//     fetchNotificationData();
//     fetchMessageData();
//     window.addEventListener('resize', handleWindowResize);
//     document.addEventListener('click', handleDocumentClick);
//     return () => {
//       window.removeEventListener('resize', handleWindowResize);
//       document.removeEventListener('click', handleDocumentClick);
//     };
//   }, []);

//   const handleWindowResize = () => {
//     // Handle window resize logic here
//   };

//   useEffect(() => {
//     const notificationCount = notificationData.length;
//     const notificationCounter = document.getElementById("notificationCounter");
//     if (notificationCounter) {
//       notificationCounter.textContent = notificationCount;
//     }
//   }, [notificationData]);

//   const fetchNotificationData = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const response = await fetch(`${BASE_URL}/api/contact-us/all`, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Auth': `Bearer ${key}`,
//         },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setNotificationData(data);
//       } else {
//         throw new Error('Failed to fetch data');
//       }
//     } catch (error) {
//       console.error('Error fetching notification data:', error);
//     }
//   };
  

//   const fetchMessageData = async () => {
//     try {
//       const key = localStorage.getItem('jwtToken');
//       const response = await fetch(`${BASE_URL}/dashboard/missingRowFoods`, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Auth': `Bearer ${key}`,
//         },
//       });
//       if (response.ok) {
//         const data = await response.json();
//         setMessageData(data.missingRowFoods);
//         setMessageCount(data.totalCount);
//       } else {
//         throw new Error('Failed to fetch message data');
//       }
//     } catch (error) {
//       console.error('Error fetching message data:', error);
//     }
//   };
  

//   const handleLogout = () => {
//     localStorage.removeItem('jwtToken');
//     history("/");
//   };

//   const toggleNotificationPopup = () => {
//     setShowNotification(!showNotification);
//   };

//   const handleMessageClick = () => {
//     setShowMessageBox(true);
//   };

//   const handleCloseMessageBox = () => {
//     setShowMessageBox(false);
//   };

//   const handleCloseNotification = () => {
//     setSelectedNotification(null);
//     setShowNotification(false);
//   };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//     // You can add search functionality here
//   };

//   const handleNotificationClick = async (notificationId) => {
//     // Handle notification click logic
//   };

//   const handleDocumentClick = (event) => {
//     if (
//       !notificationRef.current.contains(event.target) &&
//       !messageRef.current.contains(event.target)
//     ) {
//       setShowNotification(false);
//       setShowMessageBox(false);
//     }
//   };

//   return (
//     <div style={styles.navbarContainer}>
//       <div style={styles.search}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchTerm}
//           onChange={handleSearchChange}
//           style={styles.searchInput}
//         />
//       </div>
//       <div style={styles.leftItems}>
//         <Switch
//           className="icon"
//           style={styles.switch}
//           onClick={() => dispatch({ type: "TOGGLE" })}
//         />
//         <div style={styles.notificationIcon} ref={notificationRef} onClick={toggleNotificationPopup}>
//           <div style={styles.counter} id="notificationCounter">
//             {notificationData.length}
//           </div>
//           <NotificationsActiveOutlinedIcon style={styles.icon} />
//         </div>
//         <div style={styles.messageIcon} ref={messageRef} onClick={handleMessageClick}>
//           <MessageIcon style={styles.icon} />
//           <span style={styles.messageCount}>{messageCount}</span>
//         </div>
//       </div>
//       <div style={styles.rightItems}>
//         <LogoutIcon style={styles.icon} onClick={handleLogout} />
//       </div>
//       {(showNotification || selectedNotification) && (
//         <div className="notificationPopupContainer" style={styles.notificationPopupContainer}>
//           <div className="notificationBox" style={styles.notificationBox}>
//             <h3>Notifications</h3>
//             <ul style={styles.notificationList}>
//               {notificationData.map((notification, index) => (
//                 <li key={index}>
//                   <Link to="/bugs" style={{ textDecoration: 'none', color: 'inherit' }}>
//                     <strong>Its Query From:</strong> {notification.email}<br />
//                   </Link>
//                 </li>
//               ))}
//             </ul>
//             <button onClick={handleCloseNotification}>Close</button>
//           </div>
//         </div>
//       )}
//       {showMessageBox && (
//         <div className="messageBoxContainer" style={styles.messageBoxContainer}>
//           <div className="messageBox" style={styles.messageBox}>
//             <h3>Messages</h3>
//             <ul style={styles.messageList}>
//               {messageData.map((message, index) => (
//                 <li key={`message-${index}`} style={styles.messageListItem} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={isHovered ? styles.messageListItemHover : null}>
//                   <p style={styles.messageTimestamp}>{message.timestamp}</p>
//                   <p>{message.messingMessage}</p>
//                 </li>
//               ))}
//             </ul>
//             <button onClick={handleCloseMessageBox}>Close</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const styles = {
//   navbarContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '0 20px', // Adjust padding as needed
//   },
//   leftItems: {
//     display: 'flex',
//     justifyContent: 'flex-start', // Align items to the left
//   },
//   rightItems: {
//     display: 'flex',
//     justifyContent: 'flex-end', // Align items to the right
//   },
//   switch: {
//     marginRight: '10px',
//   },
//   notificationIcon: {
//     position: 'relative',
//     marginRight: '10px',
//   },
//   counter: {
//     backgroundColor: 'red',
//     color: 'white',
//     borderRadius: '90%',
//     padding: '4px 8px',
//     position: 'absolute',
//     top: '-12px',
//     right: '-9px',
//     fontSize: '0.5rem',
//     zIndex: '2',
//   },
//   messageIcon: {
//     position: 'relative',
//     marginRight: '10px',
//   },
//   messageCount: {
//     backgroundColor: 'red',
//     color: 'white',
//     borderRadius: '90%',
//     padding: '4px 8px',
//     position: 'absolute',
//     top: '-6px',
//     right: '-9px',
//     fontSize: '0.5rem',
//     zIndex: '2',
//   },
//   search: {
//     flex: '1', // Fill remaining space
//     marginRight: '20px', // Adjust margin as needed
//     marginBottom: '5px', // Add bottom margin
//   },
//   searchInput: {
//     width: '100%',
//     padding: '8px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//     fontSize: '16px',
//     boxSizing: 'border-box',
//   },
//   notificationPopupContainer: {
//     position: 'fixed',
//     top: '30%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     zIndex: '999',
//     maxHeight: '70vh',
//     overflowY: 'auto',
//   },
//   notificationBox: {
//     background: '#f0f0f0',
//     boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//     borderRadius: '5px',
//     padding: '20px',
//   },
//   messageBoxContainer: {
//     position: 'fixed',
//     top: '40%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     zIndex: '999',
//     maxHeight: '70vh',
//     overflowY: 'auto',
//   },
//   messageBox: {
//     background: '#f0f0f0',
//     boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
//     borderRadius: '5px',
//     padding: '20px',
//   },
//   notificationList: {
//     listStyleType: 'none',
//     padding: '0',
//   },
//   messageList: {
//     listStyleType: 'none',
//     padding: '0',
//   },
//   messageListItem: {
//     marginBottom: '10px', // Adjust the margin between items
//     transition: 'background-color 0.3s ease', // Add transition for smooth effect
//     cursor: 'pointer', // Change cursor to pointer on hover
//   },
//   messageTimestamp: {
//     margin: '0px 0', // Adjust the margin between timestamp and message
//   },
//   messageListItemHover: {
//     backgroundColor: 'white', // Background color on hover
//   },
// };

// export default Navbar;
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { DarkModeContext } from "../../context/darkModeContext";

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const history = useNavigate();
  const notificationRef = useRef(null);
  const messageRef = useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    fetchNotificationData();
    fetchMessageData();
    window.addEventListener("resize", handleWindowResize);
    document.addEventListener("click", handleDocumentClick);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleWindowResize = () => {
    // Handle window resize logic here
  };

  useEffect(() => {
    const notificationCount = notificationData.length;
    const notificationCounter = document.getElementById("notificationCounter");
    if (notificationCounter) {
      notificationCounter.textContent = notificationCount;
    }
  }, [notificationData]);

  const fetchNotificationData = async () => {
    try {
      const key = localStorage.getItem("jwtToken");
      const response = await fetch(`${BASE_URL}/api/contact-us/all`, {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setNotificationData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  const fetchMessageData = async () => {
    try {
      const key = localStorage.getItem("jwtToken");
      const response = await fetch(`${BASE_URL}/dashboard/missingRowFoods`, {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setMessageData(data.missingRowFoods);
        setMessageCount(data.totalCount);
      } else {
        throw new Error("Failed to fetch message data");
      }
    } catch (error) {
      console.error("Error fetching message data:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("jwtToken");
    history("/");
  };

  const toggleNotificationPopup = () => {
    setShowNotification(!showNotification);
  };

  const handleMessageClick = () => {
    setShowMessageBox(true);
  };

  const handleCloseMessageBox = () => {
    setShowMessageBox(false);
  };

  const handleCloseNotification = () => {
    setSelectedNotification(null);
    setShowNotification(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // You can add search functionality here
  };

  const handleNotificationClick = async (notificationId) => {
    // Handle notification click logic
  };

  const handleDocumentClick = (event) => {
    if (
      !notificationRef.current.contains(event.target) &&
      !messageRef.current.contains(event.target)
    ) {
      setShowNotification(false);
      setShowMessageBox(false);
    }
  };

  return (
    <div style={styles.navbarContainer}>
      <div style={styles.leftItems}>
        <Switch
          className="icon"
          style={styles.switch}
          onClick={() => dispatch({ type: "TOGGLE" })}
        />
        <div
          style={styles.notificationIcon}
          ref={notificationRef}
          onClick={toggleNotificationPopup}
        >
          <div style={styles.counter} id="notificationCounter">
            {notificationData.length}
          </div>
          <NotificationsActiveOutlinedIcon style={styles.icon} />
        </div>
        <div style={styles.messageIcon} ref={messageRef} onClick={handleMessageClick}>
          <MessageIcon style={styles.icon} />
          <span style={styles.messageCount}>{messageCount}</span>
        </div>
      </div>
      <div style={styles.rightItems}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={styles.searchInput}
        />
        <LogoutIcon style={styles.icon} onClick={handleLogout} />
      </div>
      {(showNotification || selectedNotification) && (
        <div className="notificationPopupContainer" style={styles.notificationPopupContainer}>
          <div className="notificationBox" style={styles.notificationBox}>
            <h3>Notifications</h3>
            <ul style={styles.notificationList}>
              {notificationData.map((notification, index) => (
                <li key={index}>
                  <Link to="/bugs" style={{ textDecoration: "none", color: "inherit" }}>
                    <strong>Its Query From:</strong> {notification.email}
                    <br />
                  </Link>
                </li>
              ))}
            </ul>
            <button onClick={handleCloseNotification}>Close</button>
          </div>
        </div>
      )}
      {showMessageBox && (
        <div className="messageBoxContainer" style={styles.messageBoxContainer}>
          <div className="messageBox" style={styles.messageBox}>
            <h3>Messages</h3>
            <ul style={styles.messageList}>
              {messageData.map((message, index) => (
                <li
                  key={`message-${index}`}
                  style={styles.messageListItem}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={isHovered ? styles.messageListItemHover : null}
                >
                  <p style={styles.messageTimestamp}>{message.timestamp}</p>
                  <p>{message.messingMessage}</p>
                </li>
              ))}
            </ul>
            <button onClick={handleCloseMessageBox}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  navbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    //background: "#333",
    // background: "skyblue",
    background: "rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))",
    color: "#fff",
    marginBottom: "20px",
  },
  leftItems: {
    display: "flex",
    alignItems: "center",
  },
  rightItems: {
    display: "flex",
    alignItems: "center",
  },
  switch: {
    marginRight: "10px",
  },
  notificationIcon: {
    position: "relative",
    marginRight: "10px",
  },
  counter: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "4px 8px",
    position: "absolute",
    top: "-12px",
    right: "-9px",
    fontSize: "0.65rem",
    zIndex: "2",
  },
  messageIcon: {
    position: "relative",
    marginRight: "10px",
  },
  messageCount: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    padding: "4px 8px",
    position: "absolute",
    top: "-13px",
    right: "-9px",
    fontSize: "0.65rem",
    zIndex: "2",
  },
  searchInput: {
    width: "200px",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
    boxSizing: "border-box",
    marginRight: "10px",
  },
  notificationPopupContainer: {
    position: "absolute",
    top: "50px",
    right: "20px",
    zIndex: "999",
    // maxHeight: "20vh",
    overflowY: "auto",
    background: "#fff",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    padding: "20px",
  },
  notificationBox: {
    maxWidth: "300px",
    background: "#fff", // Add a white background
    color: "#333", // Darken the text color for better visibility
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
  },

  messageBox: {
    maxWidth: "300px",
    background: "#fff", // Add a white background
    color: "#333", // Darken the text color for better visibility
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
  },
  notificationList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  },
  messageBoxContainer: {
    position: "absolute",
    top: "50px",
    right: "20px",
    zIndex: "999",
    maxHeight: "70vh",
    overflowY: "auto",
    background: "#fff",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    borderRadius: "5px",
    padding: "20px",
  },
  
  messageList: {
    listStyleType: "none",
    padding: "0",
    margin: "0",
  },
  messageListItem: {
    marginBottom: "10px",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
  },
  messageTimestamp: {
    margin: "0",
    color: "#666",
    fontSize: "0.8rem",
    marginBottom: "5px",
  },
  messageListItemHover: {
    backgroundColor: "#f0f0f0",
  },
};

export default Navbar;
