// import React, { useState } from 'react';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Sidebar from '../../components/sidebar/Sidebar';

// function Tesing() {
//   // Define state for each field
//   const [formData, setFormData] = useState({
//     source: '',
//     food_code: '',
//     food: '',
//     typesoffood: '',
//     category: '',
//     protein: '',
//     total_fat: '',
//     total_dietary_fibre: '',
//     carbohydrate: '',
//     energy_kcal: '',
//     thiamine: '',
//     riboflavin_b2: '',
//     niacin: '',
//     total_b6: '',
//     total_folate_b9: '',
//     vit_C: '',
//     vit_A: '',
//     Iron_fe: '',
//     Zinc_zn: '',
//     sodium_na: '',
//     calcium_ca: '',
//     magnesium: ''
//   });

//   // Function to handle input change
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Format the data to match the desired format
//     const formattedData = {
//       food: formData.food,
//       foodCode: formData.food_code,
//       category: formData.category,
//       source: formData.source,
//       typesOfFood: formData.typesoffood,
//       energy: parseFloat(formData.energy_kcal),
//       protein: parseFloat(formData.protein),
//       totalFat: parseFloat(formData.total_fat),
//       totalDietaryFibre: parseFloat(formData.total_dietary_fibre),
//       carbohydrate: parseFloat(formData.carbohydrate),
//       thiamineB1: parseFloat(formData.thiamine),
//       riboflavinB2: parseFloat(formData.riboflavin_b2),
//       niacinB3: parseFloat(formData.niacin),
//       vitB6: parseFloat(formData.total_b6),
//       totalFolatesB9: parseFloat(formData.total_folate_b9),
//       vitC: parseFloat(formData.vit_C),
//       vitA: parseFloat(formData.vit_A),
//       iron: parseFloat(formData.Iron_fe),
//       zinc: parseFloat(formData.Zinc_zn),
//       sodium: parseFloat(formData.sodium_na),
//       calcium: parseFloat(formData.calcium_ca),
//       magnesium: parseFloat(formData.magnesium)
//     };

//     fetch('http://localhost:7073/dashboard/add-row-food-nutrients', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(formattedData)
//     })
//     .then(response => {
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       toast.success('Data added successfully');
//       // Clear the form after successful submission if needed
//       setFormData({
//         source: '',
//         food_code: '',
//         food: '',
//         typesoffood: '',
//         category: '',
//         protein: '',
//         total_fat: '',
//         total_dietary_fibre: '',
//         carbohydrate: '',
//         energy_kcal: '',
//         thiamine: '',
//         riboflavin_b2: '',
//         niacin: '',
//         total_b6: '',
//         total_folate_b9: '',
//         vit_C: '',
//         vit_A: '',
//         Iron_fe: '',
//         Zinc_zn: '',
//         sodium_na: '',
//         calcium_ca: '',
//         magnesium: ''
//       });
//     })
//     .catch(error => {
//       console.error('There was a problem with your fetch operation:', error);
//       toast.error('Error adding data');
//     });
//   };

//   return (
//     <div style={styles.container}>
//       <Sidebar />
//       <div style={styles.formContainer}>
//         <form onSubmit={handleSubmit} style={styles.form}>
//           <h2 style={styles.heading}>Enter Food Details</h2>
//           {/* Render input fields */}
//           {Object.keys(formData).map((field, index) => (
//             <div key={index} style={styles.formGroup}>
//               <label style={styles.label}>{field.replace(/_/g, ' ')}</label>
//               <input
//                 type="text"
//                 name={field}
//                 value={formData[field]}
//                 onChange={handleChange}
//                 style={styles.input}
//               />
//             </div>
//           ))}
//           <button type="submit" style={styles.button}>Submit</button>
//         </form>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// }

// const styles = {
//   container: {
//     display: 'flex',
//   },
//   formContainer: {
//     flex: 1,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   form: {
//     padding: '20px',
//     backgroundColor: '#f9f9f9',
//     borderRadius: '8px',
//     boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
//     maxWidth: '600px', // Limit form width
//     width: '100%', // Make form responsive
//   },
//   heading: {
//     marginBottom: '20px',
//     color: '#333',
//   },
//   formGroup: {
//     marginBottom: '20px',
//   },
//   label: {
//     display: 'block',
//     marginBottom: '5px',
//     color: '#555',
//   },
//   input: {
//     width: '100%',
//     padding: '10px',
//     border: '1px solid #ccc',
//     borderRadius: '4px',
//     boxSizing: 'border-box',
//     fontSize: '16px',
//   },
//   button: {
//     padding: '10px 20px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//     transition: 'background-color 0.3s',
//     fontSize: '16px',
//   },
// };

// export default Tesing;



import React, { useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import BASE_URL from '../../components/config/apiConfig';

function Tesing() {
  const history = useSubmit();

  const [formData, setFormData] = useState({
    source: '',
    food_code: '',
    food: '',
    typesoffood: '',
    category: '',
    protein: '',
    total_fat: '',
    total_dietary_fibre: '',
    carbohydrate: '',
    energy_kcal: '',
    thiamine: '',
    riboflavin_b2: '',
    niacin: '',
    total_b6: '',
    total_folate_b9: '',
    vit_C: '',
    vit_A: '',
    Iron_fe: '',
    Zinc_zn: '',
    sodium_na: '',
    calcium_ca: '',
    magnesium: ''
  });

  const labels = {
    food: 'Food Name',
    food_code: 'Food Code',
    category: 'Group',
    source: 'Source',
    typesoffood: 'Type of Food',
    protein: 'Protein',
    total_fat: 'Total Fat',
    total_dietary_fibre: 'Total Dietary Fibre',
    carbohydrate: 'Carbohydrate',
    energy_kcal: 'Energy (kcal)',
    thiamine: 'Thiamine (B1)',
    riboflavin_b2: 'Riboflavin (B2)',
    niacin: 'Niacin (B3)',
    total_b6: 'Total (B6)',
    total_folate_b9: 'Total Folate (B9)',
    vit_C: 'Vitamin (C)',
    vit_A: 'Vitamin (A)',
    Iron_fe: 'Iron (Fe)',
    Zinc_zn: 'Zinc (Zn)',
    sodium_na: 'Sodium (Na)',
    calcium_ca: 'Calcium (Ca)',
    magnesium: 'Magnesium'
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
      food: formData.food,
      foodCode: formData.food_code,
      category: formData.category,
      source: formData.source,
      typesOfFood: formData.typesoffood,
      energy: parseFloat(formData.energy_kcal),
      protein: parseFloat(formData.protein),
      totalFat: parseFloat(formData.total_fat),
      totalDietaryFibre: parseFloat(formData.total_dietary_fibre),
      carbohydrate: parseFloat(formData.carbohydrate),
      thiamineB1: parseFloat(formData.thiamine),
      riboflavinB2: parseFloat(formData.riboflavin_b2),
      niacinB3: parseFloat(formData.niacin),
      vitB6: parseFloat(formData.total_b6),
      totalFolatesB9: parseFloat(formData.total_folate_b9),
      vitC: parseFloat(formData.vit_C),
      vitA: parseFloat(formData.vit_A),
      iron: parseFloat(formData.Iron_fe),
      zinc: parseFloat(formData.Zinc_zn),
      sodium: parseFloat(formData.sodium_na),
      calcium: parseFloat(formData.calcium_ca),
      magnesium: parseFloat(formData.magnesium)
    };

    fetch(`${BASE_URL}/dashboard/add-row-food-nutrients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedData)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      toast.success('Data added successfully');
      setFormData({
        source: '',
        food_code: '',
        food: '',
        typesoffood: '',
        category: '',
        protein: '',
        total_fat: '',
        total_dietary_fibre: '',
        carbohydrate: '',
        energy_kcal: '',
        thiamine: '',
        riboflavin_b2: '',
        niacin: '',
        total_b6: '',
        total_folate_b9: '',
        vit_C: '',
        vit_A: '',
        Iron_fe: '',
        Zinc_zn: '',
        sodium_na: '',
        calcium_ca: '',
        magnesium: ''
      });
    })
    .catch(error => {
      console.error('There was a problem with your fetch operation:', error);
      toast.error('Error adding data');
    });
  };

  const handleBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <div style={styles.container}>
      <Sidebar />
      <div style={styles.formContainer}>
        <form onSubmit={handleSubmit} style={styles.form}>
          <h2 style={styles.heading}>Enter Raw Ingredient Details</h2>
          {Object.keys(formData).map((field, index) => (
            <div key={index} style={styles.formGroup}>
              <label style={styles.label}>{labels[field]}</label>
              {field === 'typesoffood' ? (
                <select
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="vegetarian">Vegetarian</option>
                  <option value="non-vegetarian">Non-Vegetarian</option>
                </select>
              ) : (
                <input
                  type="text"
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  style={styles.input}
                />
              )}
            </div>
          ))}
          <div style={styles.buttonContainer}>
            <button type="button" onClick={handleBack} style={styles.buttonBack}>
              Back
            </button>
            <button type="submit" style={styles.button}>Submit</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
  },
  heading: {
    marginBottom: '20px',
    color: '#333',
    textAlign: 'center'
  },
  formGroup: {
    marginBottom: '20px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    fontSize: '16px',
  },
};

export default Tesing;
 
