

// =====================================================


   
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';  
import Sidebar from '../../components/sidebar/Sidebar';
import { BASE_URL } from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./statuspage.scss"; // Import external CSS fi
const Statuspage = () => {
  const [userData, setUserData] = useState(null);
  const { userId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const key = localStorage.getItem('jwtToken');

        if (userId) {
          const response = await axios.get(`${BASE_URL}/dashboard/userStatus?userId=${userId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Auth': `Bearer ${key}`,
            },
          });
          setUserData(response.data);
        } else {
          console.error('userId is undefined');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div>
      <style>
        {`
          .status-page-container {
            display: flex;
          }

          .sidebar {
            z-index: 1;
          }

          .status-page-content {
            margin-left: 250px;
            width: calc(100% - 250px);
            box-sizing: border-box;
            padding: 10px;
          }

          .basic-info-container,
          .remaining-info-container {
            background-color: #f2f2f2;
            padding: 10px;
            margin-bottom: 20px;
            width: 100%;
            box-sizing: border-box;
          }

          h3 {
            color: #333;
          }
          .heading-line {
            width: 100%;
            height: 2px;
            background-color: #ddd;
            margin-bottom: 10px;
          }
          .info-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            background-color: #f7f7f7;
            padding: 5px;
            transition: background-color 0.3s ease;
          }

          .info-item:hover {
            background-color: #e0e0e0;
          }

          .info-item-value {
            color: #555;
          }

          @media only screen and (max-width: 992px) {
            .status-page-content {
              margin-left: 0;
              width: 100%;
            }
          }
        `}
      </style>
      <RedirectComponent />

      <div className="status-page-container">
      <div className="scrollableSidebar">
                <Sidebar />
            </div>
        <div className="status-page-content">
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>User Dashboard</h2>
          <div className="heading-line"></div>
          {userData ? (
            <div>
              <div className="basic-info-container">
                <h3>Basic Information</h3>
                <p className="info-item"><strong>User Name:</strong> <span className="info-item-value">{userData.userName}</span></p>
                <p className="info-item"><strong>Name:</strong> <span className="info-item-value">{userData.name}</span></p>
                <p className="info-item"><strong>Email ID:</strong> <span className="info-item-value">{userData.emailId}</span></p>
                <p className="info-item"><strong>Mobile Number:</strong> <span className="info-item-value">{userData.mobileNo}</span></p>
                <p className="info-item"><strong>State:</strong> <span className="info-item-value">{userData.state}</span></p>
                <p className="info-item"><strong>Age:</strong> <span className="info-item-value">{userData.age}</span></p>
              </div>

              <div className="remaining-info-container">
  <h3>Remaining Information</h3>
  <p className="info-item"><strong>Average Steps Last Week:</strong> <span className="info-item-value">{userData.averageStepsLastWeek.toFixed(2)}</span></p>
      <p className="info-item"><strong>Average Hours of Sleep Per Day:</strong> <span className="info-item-value">{userData.averageHoursOfSleepPerDay.toFixed(2)}</span></p>
      <p className="info-item"><strong>Average Water Intake Per Day:</strong> <span className="info-item-value">{userData.averageWaterIntakePerDay.toFixed(2)}</span></p>
  <p className="info-item"><strong>Dish Count:</strong> <span className="info-item-value">{userData.dishCount}</span></p>
  <p className="info-item"><strong>Most Frequently Consumed Meal:</strong> <span className="info-item-value">{userData.mostFrequentlyConsumedMeal}</span></p>
  <p className="info-item"><strong>Most Skipped Meal:</strong> <span className="info-item-value">{userData.mostSkippedMeal}</span></p>
  <p className="info-item"><strong>Most Consumed Dish:</strong> <span className="info-item-value">{userData.mostConsumedDish}</span></p>
  <p className="info-item"><strong>Most Consumed Breakfast:</strong> <span className="info-item-value">{userData.mostConsumedBreakfast}</span></p>
  <p className="info-item"><strong>Most Consumed Lunch:</strong> <span className="info-item-value">{userData.mostConsumedLunch}</span></p>
  <p className="info-item"><strong>Most Consumed Dinner:</strong> <span className="info-item-value">{userData.mostConsumedDinner}</span></p>
  <p className="info-item"><strong>Most Consumed Snacks:</strong> <span className="info-item-value">{userData.mostConsumedSnacks}</span></p>
  <p className="info-item"><strong>Most Consumed Drink:</strong> <span className="info-item-value">{userData.mostConsumedDrink}</span></p>
  <p className="info-item"><strong>Most Consumed Nutrient:</strong> <span className="info-item-value">{userData.mostConsumedNutrient}</span></p>
  <p className="info-item"><strong>Least Consumed Nutrient:</strong> <span className="info-item-value">{userData.leastConsumedNutrient}</span></p>
  <p className="info-item"><strong>Most Protein Rich Diet:</strong> <span className="info-item-value">{userData.mostProteinRichDiet}</span></p>
  <p className="info-item"><strong>Most Iron Rich Diet:</strong> <span className="info-item-value">{userData.mostIronRichDiet}</span></p>
  <p className="info-item"><strong>Most Calcium Rich Diet:</strong> <span className="info-item-value">{userData.mostCalciumRichDiet}</span></p>
  <p className="info-item"><strong>Most Calorie Rich Diet:</strong> <span className="info-item-value">{userData.mostCalorieRichDiet}</span></p>
  <p className="info-item"><strong>Most CHO Rich Diet:</strong> <span className="info-item-value">{userData.mostCHORichDiet}</span></p>
</div>

            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statuspage;


