

import React, { useState, useEffect } from 'react';
import BASE_URL from '../../components/config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock } from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
  const [password, setPassword] = useState(localStorage.getItem('savedPassword') || '');
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const history = useNavigate();

  useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(rememberMeValue);
  }, []);

  const getJwtToken = () => {
    const tokenCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('jwt='));
    return tokenCookie ? tokenCookie.split('=')[1] : null;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSignIn = async () => {
    try {
      const response = await fetch(`${BASE_URL}/dashboard/user-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      if (!response.ok) {
        throw new Error('Invalid email or password');
      }

      const data = await response.json();
      console.log('Response Data:', data);

      // Store the JWT token in localStorage
      localStorage.setItem('jwtToken', data.jwt);

      const jwtToken = getJwtToken();
      console.log('JWT Token:', jwtToken);

      localStorage.setItem('rememberMe', rememberMe.toString());

      if (rememberMe) {
        localStorage.setItem('savedEmail', email);
        localStorage.setItem('savedPassword', password);
      } else {
        localStorage.removeItem('savedEmail');
        localStorage.removeItem('savedPassword');
      }

      history('/home');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  // Internal CSS
  const internalStyles = `
    .header {
      position: relative;
      padding: 5px;
      border-radius: 90px;
      margin-bottom: 20px;
      margin-top: 18px;
      background-color: #F0F8FF;
    }

    .navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 90px;
    }

    .logo {
      color: black;
      margin-left: 20px;
      text-decoration: none;
      border-radius: 90px;
    }

    .iosDownloadContainer {
      padding: 1px 5px;
      border-radius: 90px;
      margin-left: 10px;
      cursor: pointer;
    }

    .formLabel {
      font-weight: bold;
    }

    .formIcon {
      margin-right: 10px;
      margin-bottom: 33px;
    }

    .formInput {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }

    .footer {
      background-color: #F0F8FF;
      border-radius: 00px;
      margin-top: 10px;
      padding-bottom: 0px;
    }

    .footerContainer {
      margin-bottom: 0;
    }
  `;

  return (
    <div className="container-fluid" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <style>{internalStyles}</style>
      {/* Header */}
      <div className="header">
        <div className="navbar navbar-expand-lg navbar-light">
          <h3 className="navbar-brand" href="#">
            Nutrify India Now 2.0
          </h3>
          <div className="navbar-nav ml-auto">
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                Download for Android
              </a>
            </div>
            <div className="iosDownloadContainer" style={{ backgroundColor: '#001f3f' }}>
              <a className="nav-item nav-link" href="#" style={{ color: '#fff', textDecoration: 'none' }}>
                Download for iOS
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Login Page */}
      <div className="row">
        <div className="col-md-6 col-12 p-5">
          <div className="d-flex flex-column justify-content-center h-100">
            <div>
              <h1 className="font-weight-bolder text-info text-gradient">Welcome back</h1>
              <p className="mb-4">Enter your email and password to sign in</p>
              <div className="mb-3">
                <div className="d-flex align-items-center mb-3">
                  <FaEnvelope className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type="email"
                    className="form-control formInput"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    autoComplete="username"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <FaLock className="mr-2 formIcon" />
                  <label className="formLabel"></label>
                  <input
                    type="password"
                    className="form-control formInput"
                    placeholder="Enter your password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="current-password"
                  />
                </div>
              </div>
              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
              </div>
              <button
                type="button"
                className="btn bg-gradient-info w-100 mb-3"
                onClick={handleSignIn}
                style={{ backgroundColor: 'rgb(23, 162, 184)' }}
              >
                Sign in
              </button>
              <div className="text-center">
                <a href="#" className="text-secondary">Forgot your password?</a>
              </div>
              <div className="text-center">
                <a href="#" className="text-secondary">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-none d-md-block backgroundContainer">
          <img
            src={`${process.env.PUBLIC_URL}/assets/curved6.jpg`}
            alt="background"
            className="img-fluid"
            style={{
              width: '100%',
              height: '100%', 
              objectFit: 'cover',
              borderTopLeftRadius: '500px', // Slightly cut shape on the left side
              borderBottomLeftRadius: '0px', // Straight shape on the bottom left
            }}
          />
        </div>
      </div>

      {/* Footer */}
      <footer className="footer py-0">
        <div className="container-fluid footerContainer">
          <div className="row">
            <div className="col-12 text-center">
              <div className="mb-4">
                <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Terms & Conditions</a>
              </div>
              <div className="mb-4">
                <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>About Us</a>
              </div>
              <div className="mb-4">
                <a href="#" className="text-secondary" style={{ textDecoration: 'none' }}>Privacy Policy</a>
              </div>
            </div>
          </div>
          <div className="row" style={{ alignItems: 'center' }}>
            <div className="col-12 text-center mt-1">
              <p className="text-secondary mb-0">
                &copy; {new Date().getFullYear()} National Institute Of Nutrition
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Login;
