// // src/Footer.js
// import React from 'react';
// import styled from 'styled-components';

// const FooterContainer = styled.footer`
//     background-color: #324e6a;
//     color: white;
//     text-align: center;
//     padding: 20px;
//     position: relative;
//     bottom: 0;
//     width: 100%;
// `;

// const Footer = () => (
//     <FooterContainer>
//         <p>&copy; 2024 Nutrify India Now. All rights reserved.</p>
//     </FooterContainer>
// );

// export default Footer;
// src/Footer.js
import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-color: #324e6a;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
`;

const Footer = () => (
    <FooterContainer>
        <p>&copy; 2024 Nutrify India Now. All rights reserved.</p>
        <p><a href="/privacypolicy" style={{ color: 'white', textDecoration: 'none' }}>Privacy Policy</a></p>
    </FooterContainer>
);

export default Footer;
