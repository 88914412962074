// src/App.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import styled from 'styled-components';

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
`;

const Content = styled.main`
    flex: 1;
`;

const Section = styled.section`
    padding: 20px;
`;

const WelcomeSection = styled(Section)`
    background-color: #e5eff3;
    padding: 40px;
`;

const Title = styled.h2`
    color: #324e6a;
    font-size: 20px;
`;

const Paragraph = styled.p`
    color: #324e6a;
    font-size: 16px;
    line-height: 1.6;
`;

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
    color: #324e6a;
    font-size: 16px;
`;

const FeatureItem = styled.li`
    margin: 10px 0;
`;

const AppLink = styled.a`
    color: #7CB2D0;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        text-decoration: underline;
    }
`;

function App() {
    return (
        <AppContainer>
            <Header />
            <Content>
                <WelcomeSection>
                    <Title>Welcome to Nutrify India Now 2.0 by ICMR NIN! 🌟</Title>
                    <Paragraph>
                        Empowering Health Through Nutrition 🥗💪
                        <br />
                        At ICMR-National Institute of Nutrition (NIN), we are committed to promoting health and well-being through accurate nutritional guidance. Nutrify India Now 2.0 is our advanced health application designed to help you achieve your nutrition and fitness goals with ease. Whether you’re a fitness enthusiast, a health-conscious individual, or someone looking to lead a healthier lifestyle, our app is tailored to meet your needs.
                    </Paragraph>
                </WelcomeSection>
                <Section id="features">
                    <Title>Why Choose Nutrify India Now 2.0? 🤔</Title>
                    <FeatureList>
                        <FeatureItem>Track Your Health 📊: Monitor your daily activity, body metrics, and meals effortlessly. Our app helps you stay on top of your nutrition by providing insights into your dietary habits and physical activity.</FeatureItem>
                        <FeatureItem>Nutrient Health Calculators 🧮: Get precise calculations on your nutritional intake. Our health calculators are designed to help you understand your body's needs better and guide you towards a balanced diet.</FeatureItem>
                        <FeatureItem>Smartwatch Integration ⌚: Sync your smartwatch to seamlessly integrate your health data, including step count, heart rate, and more, for a comprehensive view of your health.</FeatureItem>
                        <FeatureItem>Customizable Goals 🎯: Set and track your health and fitness goals. Whether it’s weight management, improving nutrition, or enhancing physical activity, Nutrify India Now 2.0 supports you every step of the way.</FeatureItem>
                        <FeatureItem>Sleep and Hydration Tracking 💤💧: Keep track of your sleep patterns and daily water intake to ensure you’re staying hydrated and well-rested, both of which are essential for optimal health.</FeatureItem>
                        <FeatureItem>User Profiles and Personalization 👤: Create a personalized profile to receive tailored health tips and recommendations based on your individual needs and preferences.</FeatureItem>
                        <FeatureItem>Book-Buy System 📚: Explore and purchase health-related books directly through the app, with convenient delivery options available.</FeatureItem>
                    </FeatureList>
                </Section>
                <Section id="explore">
                    <Title>Explore the App 🔍</Title>
                    <FeatureList>
                        <FeatureItem>Daily Meal Tracker 🍽: Log your meals and receive feedback on your nutritional choices. Our app provides insights to help you make healthier food decisions every day.</FeatureItem>
                        <FeatureItem>Activity Monitoring 🏃‍♂: Stay active and motivated by tracking your physical activity levels. The app offers reminders and tips to keep you moving throughout the day.</FeatureItem>
                        <FeatureItem>Wake-Up Time and Sleep Tracking ⏰: Ensure you’re getting enough rest by tracking your sleep cycles. Nutrify India Now 2.0 helps you understand the importance of sleep in maintaining a healthy lifestyle.</FeatureItem>
                        <FeatureItem>Water Tracking 🚰: Keep track of your water intake to ensure you’re staying hydrated. The app makes it easy to log and monitor your daily water consumption.</FeatureItem>
                    </FeatureList>
                </Section>
                <Section id="community">
                    <Title>Join the Nutrify India Community 🤝</Title>
                    <Paragraph>
                        Be part of a growing community dedicated to health and wellness. Nutrify India Now 2.0 offers you the tools and support you need to lead a healthier life. Download the app today and start your journey towards better nutrition and overall well-being.
                    </Paragraph>
                    <Paragraph>
                        Download Now 📲
                        <br />
                        <AppLink href="#">[App Store]</AppLink> | <AppLink href="#">[Google Play]</AppLink>
                    </Paragraph>
                </Section>
            </Content>
            <Footer />
        </AppContainer>
    );
}

export default App;
