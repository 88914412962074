// src/Header.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
    background-color: #324e6a;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
`;

const LogoImage = styled.img`
    height: 40px;
    margin-right: 10px;
`;

const Nav = styled.nav`
    display: flex;
    gap: 20px;
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    font-size: 18px;

    &:hover {
        text-decoration: underline;
    }
`;

const Header = () => (
    <HeaderContainer>
        <Logo>
            <LogoImage src={`${process.env.PUBLIC_URL}/assets/NIN 2.0 Logo 12 June 2024.png`} alt="Nutrify India Now Logo" />
            Nutrify India Now
        </Logo>
        <Nav>
            <NavLink to="">About Us</NavLink>
            <NavLink to="/contactus">Contact Us</NavLink>
        </Nav>
    </HeaderContainer>
);

export default Header;
