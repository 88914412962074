// import React from 'react';
// import './privacypolicy.css'; // Make sure to create a CSS file for styling

// const ServiceAgreement = () => {
//   return (
//     <div className="service-agreement">
//        <h3 style={{ textAlign: 'center' }}>Nutrify India Now (NIN 2.0)</h3>
//       <h5>SERVICE AGREEMENT AND DISCLAIMER</h5>
//       <p>
//         This service agreement constitutes a legally binding contract between the “USER” and “ICMR-NIN” producing the Nutrify India Now (NIN 2.0) application. The contract stipulates terms and conditions of engagement between the “USER” and the Nutrify India Now (NIN 2.0) application.
//       </p>
//       <p>
//         NIN 2.0 offers “USERS” features that include derivation of Basal Metabolic Rate (BMR), Body Mass Index (BMI), Energy balance, body weight tracking, water intake, sleep and physical activity patterns.
//       </p>
//       <p>
//         NIN 2.0 also offers unique features that allow users to assess accurate nutrient composition of foods and recipes based on scientifically validated food and nutrient data generated by ICMR-NIN. Key features include recipe customization by substitution, addition of ingredients, and change of ingredient quantity or modification of portion sizes. Additionally, the app also allows USERS to create personalized recipes.
//       </p>
//       <p>
//         The quality of the data output may vary based on the inputs provided by the USER. However, the final values will be achieved to the approximate only. The final dietary recommendations by the app are based on the inputs given by the user. The USERS are therefore advised to consult the nutritionist/dieticians/physicians/health care providers before implementing the final suggestions/recommendations.
//       </p>
//       <p>
//         Personal data includes name, email ID, contact number, device ID, cookies or network identifiers and other health-related data such as dietary habits, physical activity, heart rate, sleep data, BMI. The personal data entered by the USER in the NIN 2.0 app are processed to give output in the form of meal plans, data analytics, and other food and nutrient recommendations.
//       </p>
//       <p>
//         ICMR-NIN uses this data to improve the services, recommendations, and health advises. ICMR-NIN is not liable for any breach of personal data due to either technical error or any other unforeseen means.
//       </p>
//       <p>
//         NIN 2.0 also enables USERS to purchase food and nutrition books published by ICMR-NIN, Hyderabad.
//       </p>
//       <p>
//         This service agreement regulates the access and usage of NIN 2.0 application and is subject to modification at the discretion of “ICMR-NIN” and any such amendments will be communicated via the NIN 2.0 application.
//       </p>
//       <p>
//         “USER” is responsible for periodically reviewing this service agreement for any modifications. The continued engagement of the “USER” with NIN 2.0 services automatically implies their acceptance of the updated terms and conditions.
//       </p>
//       <p>
//         At any point in time, the USER interaction with NIN 2.0 is governed by the latest version of the service contract available at that time.
//       </p>
//       <p>
//         “ICMR-NIN” herewith declares that the information provided in NIN 2.0 app is independent of guidelines issued by Indian Regulatory bodies such as the Central Drugs Standard Control Organization (CDSCO) and The Food Safety and Standards Authority of India (FSSAI).
//       </p>
//       <p>
//         Upon gaining access to the NIN 2.0 application either as a guest login or as a registered member login, the “USER” by default consents to adhere to the terms in this agreement.
//       </p>
//       <p>
//         The current NIN 2.0 application is a beta version. The current terms and conditions and privacy policy will be updated once the final version of the app is released.
//       </p>
//       <h5>USER REGISTRATION & ACCESS TO NIN 2.0 APP</h5>
//       <p>
//         “USERS” may register at the NIN 2.0 app and open a membership account after which full access is granted to all the app features and functions.
//       </p>
//       <p>
//         By registering at NIN 2.0 app the “USERS” affirm that their data inputs are accurate and complete and also consent to keep their supplied information up to date.
//       </p>
//       <p>
//         Registration by “USERS” signifies compliance with the terms and obligations outlined in this agreement, as well as adherence to applicable local, state, national, and international laws and regulations.
//       </p>
//       <p>
//         Upon successful registration, “USERS” receive a membership/registration number and a one-time password for initial login. Users must immediately change the password and are prohibited from sharing this information with any third party.
//       </p>
//       <p>
//         “USERS” supplied data is governed by the "ICMR-NIN" data collection, sharing, and privacy policies. "ICMR-NIN" reserves the right to request additional relevant information from “USERS” beyond that provided during registration.
//       </p>
//       <p>
//         “ICMR-NIN” reserves the right to withdraw the NIN 2.0 app service or modify the features or restrict access to features and functions at its sole discretion. ‘ICMR-NIN” cannot be held liable for unavailability or inaccessibility to NIN 2.0 app and its features.
//       </p>
//       <p>
//         Alterations or modifications to the NIN 2.0 app are exclusively executed by the authorized agency of "ICMR-NIN".
//       </p>
//       <p>
//         “USERS” are prohibited from copying or distributing the content and attributes of the NIN 2.0 app through any medium without explicit authorization from "ICMR-NIN". "ICMR-NIN" reserves the right to disable “USERS” accounts and delete profiles with 24-hour notice.
//       </p>
//       <p>
//         “USERS” are solely responsible for all activities conducted through their accounts. Any unauthorized use of a “USERS” account or login issues must be promptly reported to nutrifyindianow.nin@gmail.com from the registered email ID.
//       </p>
//       {/* Add more sections similarly */}
//     </div>
//   );
// };

// export default ServiceAgreement;
import React from 'react';
import './privacypolicy.css'; // Ensure this path is correct based on your file structure

const ServiceAgreement = () => {
  return (
    <div className="service-agreement">
      <h3 style={{ textAlign: 'center' }}>Nutrify India Now (NIN 2.0)</h3>
      <h5>SERVICE AGREEMENT AND DISCLAIMER</h5>
      <p>
        This service agreement constitutes a legally binding contract between the “USER” and “ICMR-NIN” producing the Nutrify India Now (NIN 2.0) application. The contract stipulates terms and conditions of engagement between the “USER” and the Nutrify India Now (NIN 2.0) application.
      </p>
      <p>
        NIN 2.0 offers “USERS” features that include derivation of Basal Metabolic Rate (BMR), Body Mass Index (BMI), Energy balance, body weight tracking, water intake, sleep and physical activity patterns.
      </p>
      <p>
        NIN 2.0 also offers unique features that allow users to assess accurate nutrient composition of foods and recipes based on scientifically validated food and nutrient data generated by ICMR-NIN. Key features include recipe customization by substitution, addition of ingredients, and change of ingredient quantity or modification of portion sizes. Additionally, the app also allows USERS to create personalized recipes.
      </p>
      <p>
        The quality of the data output may vary based on the inputs provided by the USER. However, the final values will be achieved to the approximate only. The final dietary recommendations by the app are based on the inputs given by the user. The USERS are therefore advised to consult the nutritionist/dieticians/physicians/health care providers before implementing the final suggestions/recommendations.
      </p>
      <p>
        Personal data includes name, email ID, contact number, device ID, cookies or network identifiers and other health-related data such as dietary habits, physical activity, heart rate, sleep data, BMI. The personal data entered by the USER in the NIN 2.0 app are processed to give output in the form of meal plans, data analytics, and other food and nutrient recommendations.
      </p>
      <p>
        ICMR-NIN uses this data to improve the services, recommendations, and health advises. ICMR-NIN is not liable for any breach of personal data due to either technical error or any other unforeseen means.
      </p>
      <p>
        NIN 2.0 also enables USERS to purchase food and nutrition books published by ICMR-NIN, Hyderabad.
      </p>
      <p>
        This service agreement regulates the access and usage of NIN 2.0 application and is subject to modification at the discretion of “ICMR-NIN” and any such amendments will be communicated via the NIN 2.0 application.
      </p>
      <p>
        “USER” is responsible for periodically reviewing this service agreement for any modifications. The continued engagement of the “USER” with NIN 2.0 services automatically implies their acceptance of the updated terms and conditions.
      </p>
      <p>
        At any point in time, the USER interaction with NIN 2.0 is governed by the latest version of the service contract available at that time.
      </p>
      <p>
        “ICMR-NIN” herewith declares that the information provided in NIN 2.0 app is independent of guidelines issued by Indian Regulatory bodies such as the Central Drugs Standard Control Organization (CDSCO) and The Food Safety and Standards Authority of India (FSSAI).
      </p>
      <p>
        Upon gaining access to the NIN 2.0 application either as a guest login or as a registered member login, the “USER” by default consents to adhere to the terms in this agreement.
      </p>
      <p>
        The current NIN 2.0 application is a beta version. The current terms and conditions and privacy policy will be updated once the final version of the app is released.
      </p>
      <h5>USER REGISTRATION & ACCESS TO NIN 2.0 APP</h5>
      <p>
        “USERS” may register at the NIN 2.0 app and open a membership account after which full access is granted to all the app features and functions.
      </p>
      <p>
        By registering at NIN 2.0 app the “USERS” affirm that their data inputs are accurate and complete and also consent to keep their supplied information up to date.
      </p>
      <p>
        Registration by “USERS” signifies compliance with the terms and obligations outlined in this agreement, as well as adherence to applicable local, state, national, and international laws and regulations.
      </p>
      <p>
        Upon successful registration, “USERS” receive a membership/registration number and a one-time password for initial login. Users must immediately change the password and are prohibited from sharing this information with any third party.
      </p>
      <p>
        “USERS” supplied data is governed by the "ICMR-NIN" data collection, sharing, and privacy policies. "ICMR-NIN" reserves the right to request additional relevant information from “USERS” beyond that provided during registration.
      </p>
      <p>
        “ICMR-NIN” reserves the right to withdraw the NIN 2.0 app service or modify the features or restrict access to features and functions at its sole discretion. ‘ICMR-NIN” cannot be held liable for unavailability or inaccessibility to NIN 2.0 app and its features.
      </p>
      <p>
        Alterations or modifications to the NIN 2.0 app are exclusively executed by the authorized agency of "ICMR-NIN".
      </p>
      <p>
        “USERS” are prohibited from copying or distributing the content and attributes of the NIN 2.0 app through any medium without explicit authorization from "ICMR-NIN". "ICMR-NIN" reserves the right to disable “USERS” accounts and delete profiles with 24-hour notice.
      </p>
      <p>
        “USERS” are solely responsible for all activities conducted through their accounts. Any unauthorized use of a “USERS” account or login issues must be promptly reported to nutrifyindianow.nin@gmail.com from the registered email ID.
      </p>

      <h5>REGISTERED “USER” RIGHTS & PRIVILEGES </h5>

      <p>
      After registration at NIN 2.0 App, “USERS” are granted, a non-transferable, non sub-licensable and non-exclusive license to access, use the features and avail the app services for the authorised purposes only

      </p>
      <p>
      “USERS” may upload or input their data, access notifications and access settings for sharing, storing of files, documents, schedules, or other food habits, health and nutrition data. 
      </p>
      <p>“USERS” can receive the ordered books at the address furnished by them. Order cancellations or refunds are not permitted. ICMR-NIN bears no liability for product quality and quantity.
      </p>
      <p>“USERS” have access to “ICMR-NIN” data protection, sharing and privacy policy outlining how the NIN 2.0 app handles the personal information of the users and safeguards user privacy.
      </p>
      <p>“USERS” have the right to disable receiving communications by using NIN 2.0 app settings. “USERS” may unsubscribe from the services by contacting nutrifyindianow.nin@gmail.com. Unsubscribed “USERS” will no longer receive any notifications. </p>
      {/* Add more sections similarly */}
<h3>NIN 2.0 APP USER RESPONSIBILITIES & OBLIGATIONS</h3>
<p>“USERS” must comply with data protection policies while uploading material on the NIN 2.0 App and while utilizing services or features. </p>
    <p>The “USERS” contributions must be accurate, factual, and genuine and must comply with legal provisions of the country from where it is posted.
    </p>
    
    <p>
    The contributed data should not belong to other person or contain details such as name, affiliation, physical address, telephone number, mobile or fax number, e- mail ID that the “USERS” does not have the right to disclose. The data must not misrepresent or impersonate any person's identity or affiliation.

    </p>
    <p>
    The content must not be defamatory/derogatory/invasive of any person and must not be offensive/obscene/hateful/vulgar/racial/ethnic, encouraging illegal activities such as money laundering/gambling, promote discrimination of race, gender, religion, community, nationality, disability, sexual orientation, detrimental to the minors, infringe intellectual property rights (copyrights/database right/trademark) or harass/upset/embarrass/alarm/annoy anyone.

    </p>
    <p>
    Content must not threaten the integrity, unity, sovereignty of the country; its relations with foreign countries, public law and order, or insult other nations.

    </p>
    <p>
    The posted must comply with The Indian Penal Code, year 1861, the Information Technology Act, year 2000, Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, year 2011, notified vide Notification G.S.R 313(E) issued by the Government of India (Ministry of Communications and Information Technology) and The Digital Personal Data Protection Act, 2023 ( Ministry of Electronics and Information Technology, Government of India)

    </p>
    <p>
    The data should be breach legal duties including the contractual obligations or duties of confidentiality owed to third parties. Content must not depict compensation or special consideration from third parties. Content must not deceive or mislead recipients regarding its origin.
    </p>
    <p>
    Content must not advocate, advice, or encourage or provoke any third party to commit or assist in unlawful or criminal acts. Content must not represent any statement likely to directly or indirectly incite or entice terrorism or convey false, inaccurate, or misleading information to the public.
    </p>
    <p>
    Content should be free of cyber viruses, hidden codes, worms, malware, or any elements that could disrupt or impair the functionality of the app's programs and files.
    </p>
    <p>
    “USERS” are strictly prohibited from distributing, selling, modifying, adapting, merging, or altering NIN 2.0 app features and content
    </p>
    <p>
    “USERS” must not engage in recompilation, reverse engineering, disassembling of App services or content, or interfere with security features, system resources, or affiliate links.

    </p>
    <p>
    “USERS” must not copy NIN 2.0 app software or code, decrypt source code, modify software or content, remove logos, legends, labels, proprietary notices, or disguise content origin. 
    </p>
    <p>
    “USERS” must refrain from misusing NIN 2.0 domain names in email IDs
    </p>
    <p>“USERS” are prohibited involving in activities such as damaging, overburdening servers, impairing network hosting the app, unauthorized access to content, or modifying service features.</p>
    <h5>ICMR-NIN RIGHTS & PRIVILEGES</h5>
    <p>“ICMR-NIN” reserves the right to suspend or revoke access to the services if NIN 2.0 App identifies any breach of software and to remove or delete any user-posted content that, in its sole judgment, falls under any of the aforementioned categories.</p>
    <p>
    “ICMR-NIN” may review, categorize, modify, reject, or remove undesirable, inappropriate, or explicit content at its discretion. “ICMR-NIN” reserves the right to discard non-compliant information or material upon detecting violations of terms and conditions in this agreement and data protection, sharing and privacy policy.

    </p>
    <p>
    “ICMR-NIN” may disclose “USER” identities to investigate claims of intellectual property infringement or violations of privacy rights, supported by evidence.

    </p>
    <p>
    “ICMR-NIN” is empowered to scrutinize and take legal action against the breach of terms and conditions or data protection, sharing or privacy policy in accordance with the applicable law that may involve cooperation with the law enforcement authorities
    </p>
    <p>NIN 2.0 app is designed to track and monitor the usage for improving the USER interface and ensuring compliance with the terms & conditions in this service agreement.</p>
    <p>NIN 2.0 app is enabled to access, read, store and retrieve any necessary information in order to comply with law, regulation and government policies; and to safeguard the privileges, property and safety of NIN 2.0 app and its users.</p>
    
    <p>“ICMR-NIN” has the right to introduce or revise the prices of all the services and features with prior notice and mend its terms and conditions periodically. 
“ICMR-NIN” may issue warnings as appropriate and content posted on the app may be removed either temporarily or permanently if breaches are not rectified following the warning.
</p>
<p>“ICMR-NIN” may pursue legal action to recover all costs, including reasonable administrative and legal expenses, incurred because of breaches.
</p>
<p>In case of account suspension or termination, “USERS” will lose access to the account along with any stored content or data on our servers. All licenses for the App or Services that NIN 2.0 granted you will instantly expire.
</p>
<p>The aforementioned measures are not all-inclusive, and “ICMR-NIN” maintains the authority to implement any additional measures as deemed appropriate.
</p>
{/* //===========================dhbvjhredvbhj===================================== */}
<h5>NIN 2.0 APP CONTENT LICENSE & TERMS</h5>
<p>“ICMR-NIN” is responsible for the content, data, illustrations, pictures, video, audio that is accessible to the “USERS” exclusively within the NIN 2.0 App.</p>
<p>“USERS” agree that they shall utilize the NIN 2.0 app content at their own risk as “ICMR-NIN” has no control over what is posted by “USERS” using the app features and services.</p>
<p>“ICMR-NIN” assumes no liability for any harm or loss resulting from actions initiated by “USERS” or content produced, shared, or displayed by them.</p>
<p>Search results, resources, external websites, and links accessed via the NIN 2.0 App may contain objectionable content. “USER”-provided information may include products, merchants, and third-party websites. “ICMR-NIN” disclaims any responsibility or liability associated with such information.</p>
<p>The “USER" consent that the NIN 2.0 app features encompassing their own content may be processed technically and transmitted via unencrypted routes and adjusted to comply with technical requirements of various devices and networks. “USERS” acknowledge that NIN 2.0 app is permitted to do such things under this license.</p>
<p>The NIN 2.0 app functions only as a conduit for “USER” supplied content without actively reviewing or citing it and in accordance with the terms outlined in this agreement, mandated law, data protection and sharing policy and privacy policy.</p>
<p>With regard to “USER” posted content, “ICMR-NIN” retains a sub-licensable, royalty-free, perpetual, transferable, irrevocable, non-exclusive, worldwide license to use, modify, publish, replicate, edit, translate, distribute, and create copied works.</p>
<p>NIN 2.0 app has license to use the name, voice, posted content in any format, medium or technical system for improving the app features and services. “USERS” grant non-exclusive permission to other App “USERS” to access and use your content in compliance with this Agreement.</p>
<p>“ICMR-NIN” has right to make public forums available via NIN 2.0 app for community level participation as part of its services. “USERS” grant “ICMR-NIN” a permanent, irreversible, royalty-free right to use the posted data for improving the quality of its services. “USERS” certify that they have authority and rights to issue this license to “ICMR-NIN”.</p>
<h5>GENERAL DISCLAIMERS</h5>
<p>Advertisements, images, descriptions and other material may appear in NIN 2.0 App that is issued by third party. “ICMR-NIN” holds no liability for such content.</p>
<p>“ICMR-NIN” provides the NIN 2.0 app features "as is" and "as available," without claiming warranties of any kind. “USERS” acknowledge that they use the NIN 2.0 App and features at their own risk.</p>
<p>“ICMR-NIN” makes reasonable efforts to ensure uninterrupted services; however it does not guarantee error-free performance and outcomes.</p>
<p>The feed-back and information provided by the NIN 2.0 app and “ICMR-NIN” are for information purpose only and are not intended to replace expert medical advice.</p>
<p>For “USER” medical needs and circumstances, it is recommended to seek the advice of licensed healthcare expert. “USERS” should not delay seeking medical advice based on information provided in NIN 2.0 app.</p>
{/* //jhsgbjhbsjhwb================================= */}
<h5>FINANCIAL TERMS</h5>
<p>“ICMR-NIN” has the right to introduce paid features at its absolute discretion. NIN 2.0 has the right to modify the prices for the services or add new services at additional price.</p>
<p>“USERS” must immediately update any changes to their billing or contact information or payment method or financial transaction at NIN 2.0 App.</p>
<p>“ICMR-NIN” has the right to introduce and integrate payment gateway of its choice.</p>
<p>“USERS” acknowledge and agree that no requests for refunds or compensation for purchases made through the app will be granted.</p>
{/* //=================================INDEMNITY AND LIMITATION OF LIABILITY OF “ICMR-NIN”======================= */}
<h5>INDEMNITY AND LIMITATION OF LIABILITY OF “ICMR-NIN”</h5>
<p>“USERS” agree to indemnify and hold harmless the NIN 2.0 app and its producers (‘ICMR-NIN’) from any losses, damages, costs, liabilities, and expenses (including attorney's fees) arising from: (i) “USERS” access and use of the App and its Services, (ii) “USERS” breach of the Agreement’s terms and conditions, (iii) “USERS” infringement of any third-party rights including privacy, copyright, or property rights, (iv) any claims arising from “USERS” content causing harm to a third party. This indemnification obligation governs the Agreement and “USERS” use of the Service.</p>
<p>“ICMR-NIN” does not provide any guarantees or warranties on the accuracy of content displayed on NIN 2.0 App.</p>
<p>“ICMR-NIN” and its associates have no liability for any direct or indirect or consequential loss or damage (income/revenue loss/ business loss/loss of profits and contracts/loss of savings/business loss/data loss/loss of goodwill/loss of time) that any “USERS” may suffer in relation to the NIN 2.0 App and services.</p>
<p>“ICMR-NIN” liability to “USERS” for any reason under all or any circumstances is limited to the amount paid to buy books using the NIN 2.0 app.</p>
{/* //=====================OFFENCES============================ */}
<h5>OFFENCES</h5>
<p>“USERS” agree not to intentionally or knowingly introduce malware, spyware, time bombs, trojans, logic bombs, worms, cancel bots, or any other harmful computer programming routines, files, codes, or programs into the NIN 2.0 App. “USERS” also agree not to engage in activities that infringe upon the rights or interests of other users or that restrict the functionality of any software, hardware, computer, or telecommunications.</p>
<p>“USERS” must refrain from attempting to obtain unauthorized access to NIN 2.0 App, the server hosting them, or any associated computer, database, or server that is linked to NIN 2.0 App.</p>
<p>“USERS” who violate this clause would be guilty of a crime in accordance with the Information Technology Act of 2000 including its revisions. In the case of such a breach, “USERS” access to NIN 2.0 app will be immediately terminated.</p>
<p>“ICMR-NIN” disclaims any liability for any harm or loss resulting from using NIN 2.0 App, downloading any posted content from it, or from using any other resources linked to it including viruses and other technologically harmful materials that could affect your computer programs, hardware, data, or other proprietary material.</p>
{/* //===================================ADDITIONAL TERMS OF MOBILE APP USAGE=========================== */}
<h5>ADDITIONAL TERMS OF MOBILE APP USAGE</h5>
<p>“USERS” agree that they only have a limited, non-sub-licensable access to the NIN 2.0 App. “USERS” agree that they are solely responsible for the content they post, input or supply.</p>
<p>“USERS” agree that “ICMR-NIN” shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising from the use of the NIN 2.0 App, even if “ICMR-NIN” has been advised of the possibility of such damages.</p>
<p>“USERS” must not use the NIN 2.0 App to create software that sends unsolicited communications to any third party.</p>
<p>“ICMR-NIN” reserves the right to modify or discontinue NIN 2.0 App, temporarily or permanently with or without notice.</p>
<p>Abuse or excessive and frequent requests to the services via the NIN 2.0 app may result in temporary or permanent suspension of “USERS” access. “ICMR-NIN” in its sole discretion, will determine abuse or excessive usage.</p>
<p>NIN 2.0 App hereby grants “USERS” a non-exclusive, non-transferable, revocable license to use a compiled code copy of the App for one Member account on one mobile device owned or leased solely by “USERS”, for personal use.</p>
<p>“ICMR-NIN” makes the required software available to access the NIN 2.0 app services in mobile device and other wearable devices as well. However, “ICMR-NIN” does not warrant the compatibility across different devices.</p>
<p>“USERS” acknowledge that NIN 2.0 may issue upgraded version of the app and may electronically upgrade the version of the App automatically. “USERS” consent to such automatic upgrading on their mobile device, and agree that the terms and conditions of this Agreement will apply to all such upgrades.</p>
<p>The NIN 2.0 App may incorporate third-party code, which is governed by applicable open-source or third-party licenses, such as the End-User License Agreement (EULA), authorizing its use.</p>
<p>NIN 2.0 app reserves all rights not expressly granted under this Agreement.</p>
{/* //=========================SECURITY==================== */}
<h5>SECURITY</h5>
<p>“USERS” acknowledge that they provide personal information at their own risk. “ICMR-NIN” employs reasonable organizational and technical measures to protect “USERS” personal information and content from unauthorized access, use, disclosure, alteration, or loss. However, there is no guarantee against unauthorized third-party breaches or misuse of such data and content.</p>

<p>Collecting and Using Your Personal Data: We at Nutrify India Now 2.0 are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our application.</p>

<p>Types of Data Collected:</p>
<p>Personal Data: When you register or use the Nutrify India Now 2.0 app, we may collect the following personal data:</p>
<ul>
  <li>Name</li>
  <li>Phone Number</li>
  <li>Email Address</li>
  <li>Password</li>
  <li>Height</li>
  <li>Weight</li>
  <li>Work Type</li>
  <li>Date of Birth (DOB)</li>
  <li>Location</li>
  <li>Address (for NIN-publication purchases)</li>
</ul>

<p>Information Collected While Using the Application: While using our application, we may also collect additional information to enhance your experience, including:</p>
<ul>
  <li>Health parameters from third-party watch SDKs</li>
  <li>Activity tracking data</li>
  <li>Daily meal records</li>
  <li>Body metrics</li>
</ul>

<p>Use of Your Personal Data: We use the collected personal data for various purposes:</p>
<ul>
  <li>To provide and maintain our service</li>
  <li>To manage your account</li>
  <li>To contact you regarding updates, offers, and other essential information</li>
  <li>To process transactions and manage NIN-publication purchases</li>
  <li>To improve our app based on user feedback and usage data</li>
  <li>To personalize your experience and provide tailored content</li>
  <li>To monitor the usage of our service and gather analytics</li>
  <li>To detect, prevent, and address technical issues</li>
</ul>

<p>Retention of Your Personal Data: We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your data to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>

<p>Security of Your Personal Data: The security of your data is important to us. We employ various security measures to protect your personal data:</p>
<ul>
  <li>Secure backend and frontend systems</li>
  <li>Encryption of sensitive data</li>
  <li>Regular security audits and updates</li>
  <li>Access controls to limit data access to authorized personnel only</li>
  <li>Use of secure payment gateways for transactions (including Razorpay for NIN publication purchases)</li>
</ul>

<p>Third-Party SDKs: We use third-party SDKs to enhance the functionality of our application. These include:</p>
<ul>
  <li>Watch SDKs: To collect health parameters and activity data</li>
  <li>Razorpay: For secure payment processing for NIN publication purchases</li>
</ul>
<p>These third-party services have their own privacy policies addressing how they use such information. We strongly advise you to review their privacy policies as well.</p>

<p>Links to Other Websites.</p>
<p>Our service may contain links to third-party websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the privacy policy of every site you visit.</p>

<p>Data Security: We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>

<p>Changes to This Privacy Policy: We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our app. Your continued use of the app after the changes become effective constitutes your acceptance of the revised Privacy Policy.</p>

<p>By using Nutrify India Now 2.0, you agree to the collection and use of information in accordance with this Privacy Policy. If you have any questions about this Privacy Policy, please contact us.</p>
{/* //=========================== INTELLECUTAL PROPERTY RIGHT/ TRADEMARKS/ SERVICEMARKS================= */}
<h5> INTELLECUTAL PROPERTY RIGHT/ TRADEMARKS/ SERVICEMARKS</h5>
<p>“ICMR-NIN” has all the rights for texts, graphics, user interfaces, visual interfaces, computer code and any other information associated with NIN 2.0 App.</p>
<p>The NIN 2.0 app and its content, data, statistics, independent research, or any posted materials may not be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed in any manner, including 'mirroring,' without prior written consent from “ICMR-NIN”, for any commercial purposes.</p>
<p>NIN 2.0, we retain the full and complete right, title and interest to the App, and all intellectual property, title and interest to the App, and all intellectual rights therein.</p>
{/* //=====================LEGAL TERMS=============================== */}
<h5>LEGAL TERMS
</h5>
<p>The terms and conditions in this service agreement for usage of NIN 2.0 app services are governed exclusively by the rules, regulations and laws of Indian Government.</p>
<p>The Jurisdictional Court of Hyderabad, Telangana state, India shall have sole jurisdiction over any claim related to or arising from the usage of the NIN 2.0 App and the Services or even a visit to it.</p>
<p>NIN 2.0 app accepts no liability whatsoever, for noncompliance with the laws of any country other than that of India, direct or indirect. The mere fact that the App can be accessed or used or any facility can be availed in a country other than India will not imply that we accede to the laws of that country.</p>



    
    
    
    
    
    
    
    </div>
  );
};

export default ServiceAgreement;
