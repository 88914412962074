
// // import React, { useRef } from 'react';
// // import BASE_URL from '../../components/config/apiConfig';

// // const divStyle = {
// //   backgroundImage: `url(${process.env.PUBLIC_URL}/assets/aboutnin.jpg)`,
// //   backgroundSize: '100% 100%', // Ensure the image covers the entire div
// //   backgroundRepeat: 'no-repeat',
// //   height: '100vh', // Adjust height as needed
// //   display: 'flex',
// //   flexDirection: 'column',
// //   justifyContent: 'center',
// //   alignItems: 'center',
// //   color: 'white', // Text color
// //   fontSize: '24px', // Adjust font size as needed
// //   textAlign: 'center'
// // };

// // const DownloadAPKButton = () => {
// //   const handleDownload = () => {
// //     window.location.href = `${BASE_URL}/dashboard/apk`;
// //   };

// //   return (
// //     <button onClick={handleDownload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>
// //       Download Nutrify India Now 2.0 App
// //     </button>
// //   );
// // };

// // const UploadAPKButton = () => {
// //   const fileInputRef = useRef(null);

// //   const handleUpload = () => {
// //     fileInputRef.current.click(); // Trigger click on file input
// //   };

// //   const handleFileChange = (event) => {
// //     const file = event.target.files[0];
// //     if (file) {
// //       // You can handle the file here, e.g., upload it using FormData
// //       console.log('Selected file:', file);
// //       // Example: Upload file using fetch or axios
// //       const formData = new FormData();
// //       formData.append('file', file);

// //       fetch(`${BASE_URL}/dashboard/uploadApk`, {
// //         method: 'POST',
// //         body: formData
// //       })
// //       .then(response => {
// //         // Handle response as needed
// //         console.log('Upload response:', response);
// //       })
// //       .catch(error => {
// //         console.error('Error uploading file:', error);
// //       });
// //     }
// //   };

// //   return (
// //     <div>
// //       <input
// //         type="file"
// //         ref={fileInputRef}
// //         style={{ display: 'none' }}
// //         onChange={handleFileChange}
// //       />
// //       <button onClick={handleUpload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', cursor: 'pointer' }}>
// //         Upload APK
// //       </button>
// //     </div>
// //   );
// // };

// // const DisplayMessage = () => {
// //   return (
// //     <div style={divStyle}>
// //      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '20px' }}>
// //   <h1 style={{ marginBottom: '20px' }}>Download Our App</h1>
// //   <p style={{ marginBottom: '20px', maxWidth: '500px' }}>Click the button below to download our app:</p>
// //   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
// //     <DownloadAPKButton /> 
// //     {/* <UploadAPKButton />
// //     Coming soon..... */}
// //   </div>
// // </div>

// //     </div>
// //   );
// // };

// // export default DisplayMessage;






// import React from 'react';
// import BASE_URL from '../../components/config/apiConfig';

// const divStyle = {
//   height: '100vh',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   color: 'white',
//   fontSize: '24px',
//   textAlign: 'center',
//   backgroundColor: '#282c34', // Background color for the container
// };

// const topImageStyle = {
//   width: '100%',
//   height: '200px', // Set height as needed
//   backgroundColor: '#ddd', // Optional: background color if no image
//   marginBottom: '20px',
// };

// const imageGridStyle = {
//   display: 'grid',
//   gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns
//   gap: '10px',
//   maxWidth: '1200px', // Adjust max width as needed
//   margin: '0 auto'
// };

// const imageStyle = {
//   width: '100%', // Ensure the image takes up full width of the grid cell
//   height: '200px', // Set height as needed
//   objectFit: 'contain', // Ensures the full image is visible without cropping
//   borderRadius: '10px' // Optional: add border radius for rounded corners
// };

// const DownloadAPKButton = () => {
//   const handleDownload = () => {
//     window.location.href = `${BASE_URL}/dashboard/apk`;
//   };

//   return (
//     <button onClick={handleDownload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>
//       Download Nutrify India Now 2.0 App
//     </button>
//   );
// };

// const UploadAPKButton = () => {
//   const fileInputRef = React.useRef(null);

//   const handleUpload = () => {
//     fileInputRef.current.click(); // Trigger click on file input
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const formData = new FormData();
//       formData.append('file', file);

//       fetch(`${BASE_URL}/dashboard/uploadApk`, {
//         method: 'POST',
//         body: formData
//       })
//       .then(response => {
//         console.log('Upload response:', response);
//       })
//       .catch(error => {
//         console.error('Error uploading file:', error);
//       });
//     }
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: 'none' }}
//         onChange={handleFileChange}
//       />
//       <button onClick={handleUpload} style={{ marginTop: '20px', padding: '10px 20px', backgroundColor: '#28a745', color: 'white', border: 'none', cursor: 'pointer' }}>
//         Upload APK
//       </button>
//     </div>
//   );
// };

// const DisplayMessage = () => {
//   return (
//     <div style={divStyle}>
//       <div style={topImageStyle}>
//         {/* Top image placeholder or content */}
//       </div>
//       <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '20px', marginBottom: '20px' }}>
//         <h1 style={{ marginBottom: '20px' }}>Download Our App</h1>
//         <p style={{ marginBottom: '20px', maxWidth: '500px' }}>Click the button below to download our app:</p>
//         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
//           <DownloadAPKButton />
//           {/* <UploadAPKButton /> */}
//           {/* Coming soon..... */}
//         </div>
//       </div>
//       <div style={imageGridStyle}>
//         <img src={`${process.env.PUBLIC_URL}/assets/image1.jpg`} alt="1" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image2.jpg`} alt="2" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image3.jpg`} alt="3" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image4.jpg`} alt="4" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image5.jpg`} alt="5" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image6.jpg`} alt="6" style={imageStyle} />
//         <img src={`${process.env.PUBLIC_URL}/assets/image7.jpg`} alt="6" style={imageStyle} />
//       </div>
//     </div>
//   );
// };

// export default DisplayMessage;
import React from 'react';
import BASE_URL from '../../components/config/apiConfig';

const divStyle = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: 'white',
  fontSize: '24px',
  textAlign: 'center',
  
};

const topImageStyle = {
  width: '100%',
  height: '250px', // Adjusted height
  backgroundImage: 'url("/path/to/your/image.jpg")', // Add a real image URL
  backgroundSize: 'cover', // Cover the entire div
  backgroundPosition: 'center', // Center the image
  marginBottom: '20px',
};

const imageGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns for the first row
  gap: '10px',
  maxWidth: '1200px', // Adjust max width as needed
  margin: '0 auto',
  padding: '0 20px', // Add padding to avoid content touching edges
  backgroundColor: '#f0f0f0', // Background color for the grid container
  borderRadius: '10px', // Optional: add border radius for rounded corners
};

const imageStyle = {
  width: '100%',
  height: '200px', // Set height as needed
  objectFit: 'cover', // Cover the image area
  borderRadius: '10px', // Optional: add border radius for rounded corners
  backgroundColor: '#cccccc', // Background color for individual grid items
};

const buttonStyle = {
  marginTop: '20px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px', // Rounded corners
  cursor: 'pointer',
  fontSize: '16px',
};

const DownloadAPKButton = () => {
  const handleDownload = () => {
    window.location.href = `${BASE_URL}/dashboard/apk`;
  };

  return (
    <button onClick={handleDownload} style={{ ...buttonStyle, backgroundColor: '#007bff', color: 'white' }}>
      Download Nutrify India Now 2.0 App
    </button>
  );
};

const UploadAPKButton = () => {
  const fileInputRef = React.useRef(null);

  const handleUpload = () => {
    fileInputRef.current.click(); // Trigger click on file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      fetch(`${BASE_URL}/dashboard/uploadApk`, {
        method: 'POST',
        body: formData
      })
      .then(response => {
        console.log('Upload response:', response);
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
    }
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <button onClick={handleUpload} style={{ ...buttonStyle, backgroundColor: '#28a745', color: 'white' }}>
        Upload APK
      </button>
    </div>
  );
};

const DisplayMessage = () => {
  return (
    <div style={divStyle}>
      <div style={topImageStyle}>
        {/* Top image placeholder or content */}
      </div>
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '20px', marginBottom: '20px', borderRadius: '10px' }}>
        <h1 style={{ marginBottom: '20px' }}>Download Our App</h1>
        <p style={{ marginBottom: '20px', maxWidth: '500px', margin: '0 auto' }}>Click the button below to download our app:</p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
          <DownloadAPKButton />
          {/* <UploadAPKButton /> */}
          {/* Coming soon..... */}
        </div>
      </div>
      <div style={imageGridStyle}>
        <img src={`${process.env.PUBLIC_URL}/assets/image1.jpg`} alt="1" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image2.jpg`} alt="2" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image3.jpg`} alt="3" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image4.jpg`} alt="4" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image5.jpg`} alt="5" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image6.jpg`} alt="6" style={imageStyle} />
        <img src={`${process.env.PUBLIC_URL}/assets/image7.jpg`} alt="7" style={imageStyle} />
      </div>
    </div>
  );
};

export default DisplayMessage;
