
import React, { useState, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BookIcon from '@mui/icons-material/Book';
import "./widget.scss";
import { BASE_URL } from '../config/apiConfig';

const Widget = ({ type }) => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAvailableBooks, setTotalAvailableBooks] = useState(0);
  const [totalBookSale, setTotalBookSale] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const key = localStorage.getItem('jwtToken');
        const userResponse = await fetch(`${BASE_URL}/dashboard/get-all-users-detail`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,
          },
        });
        const bookResponse = await fetch(`${BASE_URL}/dashboard/get`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,
          },
        });
        const orderResponse = await fetch(`${BASE_URL}/dashboard/orders`, {
          headers: {
            'Content-Type': 'application/json',
            'Auth': `Bearer ${key}`,
          },
        });

        if (!userResponse.ok || !bookResponse.ok || !orderResponse.ok) {
          throw new Error("Failed to fetch data");
        }

        const userData = await userResponse.json();
        const bookData = await bookResponse.json();
        const orderData = await orderResponse.json();

        setTotalUsers(userData.totalUsers);
        setTotalAvailableBooks(bookData.totalAvailableBook);
        setTotalBookSale(orderData.totalBookSale);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  let data;

  switch (type) {
    case "customer":
      data = {
        title: "Registered Users",
        counter: totalUsers,
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "crimson", backgroundColor: "#ff000033" }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Book Sale",
        counter: totalBookSale,
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{ color: "goldenrod", backgroundColor: "#daa52033" }}
          />
        ),
      };
      break;
    case "earnings":
      data = {
        title: "Available Book",
        counter: totalAvailableBooks,
        icon: (
          <BookIcon
            className="icon"
            style={{ color: "green", backgroundColor: "#00800033" }}
          />
        ),
      };
      break;
    default:
      data = {
        title: "Unknown",
        counter: 0,
        icon: null,
      };
      break;
  }

  return (
    <div className={`widget ${type}`}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.counter}</span>
       
      </div>
      <div className="right">
        <div className="percentage positive">
          {data.icon}
        </div>
      </div>
    </div>
  );
};

export default Widget;
