
// //=================================import file ====================================================

// import React, { useState, useEffect, useRef } from "react";
// import { Chart, registerables } from "chart.js/auto";
// import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
// import "./home.scss";
// import Sidebar from "../../components/sidebar/Sidebar";
// import "bootstrap/dist/css/bootstrap.min.css"; // Add Bootstrap CSS
// import RatingsReviews from "../../components/ratingsreviews/RatingsReviews";
// import Map from "../../components/map/Map";
// import Widget from "../../components/widget/Widget";
// import Navbar from "../../components/navbar/Navbar";
// import BASE_URL from '../../components/config/apiConfig';
// import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
// import 'chartjs-plugin-datalabels';

// Chart.register(...registerables);
// //===============================props=========================================================================

// function Dashboard(props) {
//   const [userData, setUserData] = useState({});
//   const [ageData, setAgeData] = useState({});
//   const [bmiData, setBmiData] = useState({});
//   const [caloriesData, setCaloriesData] = useState([]);
//   const [sleepData, setSleepData] = useState([]);
//   const [waterIntakeData, setWaterIntakeData] = useState({});
//   const [genderCountData, setGenderCountData] = useState({});
//   const genderCountChartRef = useRef(null);
//   const [workLevelData, setWorkLevelData] = useState({});
//   const workLevelChartRef = useRef(null);
//   const [stepsData, setStepsData] = useState([]);
//   const stepsChartRef = useRef(null);
//   const [totalStepCount, setTotalStepCount] = useState(0);
//   const [totalKms, setTotalKms] = useState(0);
  

//   const [calorieData, setCalorieData] = useState([]); // Declare setCalorieData
//   const calorieChartRef = useRef(null); // Declare calorieChartRef
   
//   const chartRef = useRef(null);
//   const ageChartRef = useRef(null);
//   const bmiChartRef = useRef(null);
//   const caloriesChartRef = useRef(null);
//   const sleepChartRef = useRef(null);
//   const waterIntakeChartRef = useRef(null);
//   const totalStepsKmsChartRef = useRef(null);

// let userRegistrationChart = null;
// let ageChart = null;
// let bmiChart = null;
// let caloriesChart = null;
// let sleepChart = null;
// let waterIntakeChart = null;
// let genderCountChart = null;
// let workLevelChart = null;

// //=====================================================hooks =====================================================
//   useEffect(() => {
//     fetchData();
//     fetchAgeData();
//     fetchBmiData();
//     //fetchCaloriesData();
//     fetchSleepData();
//     fetchWaterIntakeData(); 
//     fetchGenderCountData();
//     fetchWorkLevelData();
//     fetchStepsData();
    
//     return () => {
//       if (userRegistrationChart) {
//         userRegistrationChart.destroy();
//       }
//       if (ageChart) {
//         ageChart.destroy();
//       }
//       if (bmiChart) {
//         bmiChart.destroy();
//       }
//       // if (caloriesChart) {
//       //   caloriesChart.destroy();
//       // }
//       if (sleepChart) {
//         sleepChart.destroy();
//       }
   

//     };
//   }, []);




// //================================================kilometres by gender====================================
//   useEffect(() => {
//     if (totalStepsKmsChartRef.current) {
//       const totalStepsKmsChart = new Chart(totalStepsKmsChartRef.current, {
//         type: "bar",
//         data: {
//           labels: ["Total Steps", "Total Kilometers"],
//           datasets: [
//             {
//               label: "Total",
//               backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
//               borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
//               borderWidth: 1,
//               data: [totalStepCount, totalKms],
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           scales: {
//             yAxes: [{
//               ticks: {
//                 beginAtZero: true
//               }
//             }]
//           },
//         },
//       });
  
//       return () => {
//         totalStepsKmsChart.destroy();
//       };
//     }
//   }, [totalStepCount, totalKms]);
  
//   useEffect(() => {
//     const fetchTotalStepCountAndKms = async () => {
//       try {
//         const response = await fetch(
//           `${BASE_URL}/dashboardDemo/totalStepCountAndKms`
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setTotalStepCount(data.totalSteps);
//           setTotalKms(data.totalKms);
//         } else {
//           console.error("Failed to fetch total step count and kilometers data");
//         }
//       } catch (error) {
//         console.error("Error fetching total step count and kilometers data:", error);
//       }
//     };
  
//     fetchTotalStepCountAndKms();
//   }, []);
//   //========================================================average steps==========================================================

  
//   useEffect(() => {
//     const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
//     const maleSteps = Array(ageGroups.length).fill(0);
//     const femaleSteps = Array(ageGroups.length).fill(0);
  
//     if (stepsChartRef.current && stepsData.length > 0) {
//       stepsData.forEach(entry => {
//         const index = ageGroups.indexOf(entry.ageGroup);
//         if (index !== -1) {
//           if (entry.gender === 'Male') {
//             maleSteps[index] = parseFloat(entry.average);
//           } else if (entry.gender === 'Female') {
//             femaleSteps[index] = parseFloat(entry.average);
//           }
//         }
//       });
  
//       const stepsChart = new Chart(stepsChartRef.current, {
//         type: 'bar',
//         data: {
//           labels: ageGroups,
//           datasets: [
//             {
//               label: 'Male',
//               backgroundColor: 'rgba(54, 162, 235, 0.5)',
//               borderColor: 'rgba(54, 162, 235, 1)',
//               borderWidth: 1,
//               data: maleSteps,
//             },
//             {
//               label: 'Female',
//               backgroundColor: 'rgba(255, 99, 132, 0.5)',
//               borderColor: 'rgba(255, 99, 132, 1)',
//               borderWidth: 1,
//               data: femaleSteps,
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           aspectRatio: 2, // Set aspect ratio to fill available space
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'No.of Average Steps',
//               },
//             },
//             x: {
//               title: {
//                 display: false, // Hide x-axis title
//                 text: '',
//               },
//             },
//           },
//           plugins: {
//             title: {
//               display: true,
//               text: 'Average Steps',
//               font: {
//                 size: 16,
//                 weight: 'bold',
//               },
//             },
//             tooltip: {
//               callbacks: {
//                 label: (context) => {
//                   const datasetIndex = context.datasetIndex;
//                   const ageGroup = context.label;
//                   const gender = datasetIndex === 0 ? 'Male' : 'Female';
//                   const entry = stepsData.find(item => item.ageGroup === ageGroup && item.gender === gender);
//                   if (entry) {
//                     return [
//                       `Gender: ${gender}`,
//                       `Average Steps: ${entry.average}`,
//                       `User Count: ${entry.userCount}`,
//                       `Active Users: ${entry.activeUsers}`,
//                     ];
//                   }
//                 },
//               },
//             },
//           },
//         },
//       });
  
//       return () => {
//         stepsChart.destroy();
//       };
//     }
//   }, [stepsData]);
  
  

//   const fetchStepsData = async () => {
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/average-steps-by-age`
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setStepsData(data);
//       } else {
//         console.error("Failed to fetch steps data");
//       }
//     } catch (error) {
//       console.error("Error fetching steps data:", error);
//     }
//   };

//   //====================================worklevel================================================================


//   useEffect(() => {
//     let workLevelChart;
  
//     if (Object.keys(workLevelData).length > 0 && workLevelChartRef.current) {
//       if (workLevelChart) {
//         workLevelChart.destroy();
//       }
  
//       const labels = Object.keys(workLevelData);
//       const data = Object.values(workLevelData);
  
//       workLevelChart = new Chart(workLevelChartRef.current, {
//         type: "pie",
//         data: {
//           labels: labels,
//           datasets: [
//             {
//               data: data,
//               backgroundColor: [
//                 "rgba(255, 99, 132, 0.5)",
//                 "rgba(54, 162, 235, 0.5)",
//                 "rgba(255, 206, 86, 0.5)",
//                 "rgba(75, 192, 192, 0.5)",
//               ],
//               borderColor: [
//                 "rgba(255, 99, 132, 1)",
//                 "rgba(54, 162, 235, 1)",
//                 "rgba(255, 206, 86, 1)",
//                 "rgba(75, 192, 192, 1)",
//               ],
//               borderWidth: 1,
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           legend: {
//             display: true,
//             position: "bottom",
//             labels: {
//               fontColor: "#333",
//             },
//           },
//           plugins: {
//             title: {
//               display: true,
//               text: 'Number of Users by Work Level',
//               font: {
//                 size: 16,
//                 weight: 'bold',
//               },
//             },
//           },
//         },
//       });
//     }
  
//     return () => {
//       if (workLevelChart) {
//         workLevelChart.destroy();
//       }
//     };
//   }, [workLevelData]);
  

//   const fetchWorkLevelData = async () => {
//     //const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/api/dashboard/byWorkLevel`,
//         // {
//         //   headers: {
//         //     "Content-Type": "application/json",
//         //     Auth: `Bearer ${key}`,
//         //   },
//         // }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setWorkLevelData(data.usersByWorkLevel);
//       } else {
//         console.error("Failed to fetch work level data");
//       }
//     } catch (error) {
//       console.error("Error fetching work level data:", error);
//     }
//   };
// // ===================================================gender count ==============================================================
//   useEffect(() => {
//     if (genderCountChartRef.current && Object.keys(genderCountData).length > 0) {
//       if (genderCountChart) {
//         genderCountChart.destroy();
//       }
  
//       const labels = Object.keys(genderCountData);
//       const data = Object.values(genderCountData);
  
//       genderCountChart = new Chart(genderCountChartRef.current, {
//         type: "pie",
//         data: {
//           labels: labels,
//           datasets: [
//             {
//               data: data,
//               backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
//               borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
//               borderWidth: 1,
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           plugins: {
//             title: {
//               display: true,
//               text: 'Number of Users by Gender', // Set the title text
//               font: {
//                 size: 16,
//                 weight: 'bold',
//               },
//             },
//           },
//           legend: {
//             display: true,
//             position: "bottom",
//             labels: {
//               fontColor: "#333",
//             },
//           },
//         },
//       });
//     }
  
//     return () => {
//       if (genderCountChart) {
//         genderCountChart.destroy();
//       }
//     };
//   }, [genderCountData]);
  

//   const fetchGenderCountData = async () => {
//     const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/gender-count`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setGenderCountData(data);
//       } else {
//         console.error("Failed to fetch gender count data");
//       }
//     } catch (error) {
//       console.error("Error fetching gender count data:", error);
//     }
//   };




//   // ============================Render water intake distribution chart============================================
  
  
//   const fetchWaterIntakeData = async () => {
//     const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/average-water-intake`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setWaterIntakeData(data);
//       } else {
//         console.error("Failed to fetch water intake data");
//       }
//     } catch (error) {
//       console.error("Error fetching water intake data:", error);
//     }
//   };
  
//   useEffect(() => {
//     if (Object.keys(waterIntakeData).length > 0 && waterIntakeChartRef.current) {
//       const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
//       const maleIntake = Array(labels.length).fill(0);
//       const femaleIntake = Array(labels.length).fill(0);
  
//       if (waterIntakeChart) {
//         waterIntakeChart.destroy();
//       }
  
//       waterIntakeData.forEach((entry) => {
//         const index = labels.indexOf(entry.ageGroup);
//         if (index !== -1) {
//           if (entry.gender === 'male') {
//             maleIntake[index] = parseFloat(entry.average.split('ml')[0]);
//           } else if (entry.gender === 'female') {
//             femaleIntake[index] = parseFloat(entry.average.split('ml')[0]);
//           }
//         }
//       });
  
//       waterIntakeChart = new Chart(waterIntakeChartRef.current, {
//         type: "bar",
//         data: {
//           labels: labels,
//           datasets: [
//             {
//               label: "Male",
//               backgroundColor: "rgba(54, 162, 235, 0.5)",
//               borderColor: "rgba(54, 162, 235, 1)",
//               borderWidth: 1,
//               data: maleIntake,
//             },
//             {
//               label: "Female",
//               backgroundColor: "rgba(255, 99, 132, 0.5)",
//               borderColor: "rgba(255, 99, 132, 1)",
//               borderWidth: 1,
//               data: femaleIntake,
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           aspectRatio: 2, // Aspect ratio set to 2:1
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'Average Intake in ml', // Updated title
//               },
//             },
//             x: {
//               title: {
//                 display: false, // Remove x-axis title
//               },
//             },
//           },
//           tooltips: {
//             callbacks: {
//               label: function(tooltipItem, data) {
//                 let label = data.datasets[tooltipItem.datasetIndex].label || '';
//                 if (label) {
//                   label += ': ';
//                 }
//                 label += tooltipItem.yLabel.toLocaleString() + ' ml';
//                 return label;
//               }
//             }
//           },
//           plugins: {
//             title: {
//               display: true,
//               text: 'Average Water Intake in ml',
//               font: {
//                 size: 16,
//                 weight: 'bold'
//               }
//             },
//             tooltip: {
//               callbacks: {
//                 label: (context) => {
//                   const datasetIndex = context.datasetIndex;
//                   const ageGroup = context.label;
//                   const gender = datasetIndex === 0 ? 'male' : 'female';
//                   const entry = waterIntakeData.find(item => item.ageGroup === ageGroup && item.gender === gender);
//                   if (entry) {
//                     return [
//                       `Gender: ${gender}`,
//                       `Average Intake: ${entry.average}`,
//                       `User Count: ${entry.userCount}`
//                     ];
//                   }
//                 }
//               }
//             }
//           }
//         },
//       });
//     }
  
//     return () => {
//       if (waterIntakeChart) {
//         waterIntakeChart.destroy();
//       }
//     };
//   }, [waterIntakeData]);
  

//   // ========================================Chart rendering for User Registration=================================================
  
//   //===================================user by months======================================================
 
//   const fetchData = async () => {
//     const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/user-registration-by-month?year=2024`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setUserData(data);
//       } else {
//         console.error("Failed to fetch user registration data");
//       }
//     } catch (error) {
//       console.error("Error fetching user registration data:", error);
//     }
//   };
//   useEffect(() => {
//     if (chartRef.current && Object.keys(userData).length > 0) {
//       if (userRegistrationChart) {
//         userRegistrationChart.destroy();
//       }

//       userRegistrationChart = new Chart(chartRef.current, {
//         type: "line",
//         data: {
//           labels: Object.keys(userData),
//           datasets: [
//             {
//               label: "Registered Users",
//               fill: false,
//               lineTension: 0.1,
//               backgroundColor: "rgba(75,192,192,0.4)",
//               borderColor: "rgba(75,192,192,1)",
//               pointBackgroundColor: "#fff",
//               pointBorderColor: "rgba(75,192,192,1)",
//               pointHoverBackgroundColor: "rgba(75,192,192,1)",
//               pointHoverBorderColor: "rgba(220,220,220,1)",
//               data: Object.values(userData),
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'No.of Users by Months',
                
               
//               },
//             },
//             x: {
//               title: {
//                 display: true,
                
                
//               },
//               ticks: {
//                 autoSkip: false,
//                 fontSize: 12,
//               },
//               gridLines: {
//                 display: false,
//               },
//             },
//           },
//           legend: {
//             display: true,
//             position: "bottom",
//             labels: {
//               fontColor: "#333",
//             },
//           },
//         },
//       });
//     }

//     return () => {
//       if (userRegistrationChart) {
//         userRegistrationChart.destroy();
//       }
//     };
//   }, [userData]);

//   //================================================== Chart rendering for Age ============================================
//   //====================================================fetch age data===============================================
//   const fetchAgeData = async () => {
//     const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/age-categories`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setAgeData(data);
//       } else {
//         console.error("Failed to fetch age data");
//       }
//     } catch (error) {
//       console.error("Error fetching age data:", error);
//     }
//   };

//   useEffect(() => {
//     if (ageChartRef.current && Object.keys(ageData).length > 0) {
//       if (ageChart) {
//         ageChart.destroy();
//       }

//       ageChart = new Chart(ageChartRef.current, {
//         type: "bar",
//         data: {
//           labels: Object.keys(ageData),
//           datasets: [
//             {
//               label: "Age Distribution",
//               backgroundColor: [
//                 "rgba(255, 206, 86, 0.5)",
//                 "rgba(54, 162, 235, 0.5)",
//                 "rgba(75, 192, 192, 0.5)",
//                 "rgba(153, 102, 255, 0.5)",
//                 "rgba(255, 159, 64, 0.5)",
//               ],
//               borderColor: [
//                 "rgba(255, 206, 86, 1)",
//                 "rgba(54, 162, 235, 1)",
//                 "rgba(75, 192, 192, 1)",
//                 "rgba(153, 102, 255, 1)",
//                 "rgba(255, 159, 64, 1)",
//               ],
//               borderWidth: 1,
//               data: Object.values(ageData),
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'No. of Users by Age',
//               },
//             },
//           },
//         },
//       });
//     }

//     return () => {
//       if (ageChart) {
//         ageChart.destroy();
//       }
//     };
//   }, [ageData]);

//   // =============================================Chart rendering for BMI Distribution==================================================
//   ///========================================fetch data by bmi================================================
//   const fetchBmiData = async () => {
//     const key = localStorage.getItem("jwtToken");
//     try {
//       const response = await fetch(
//         `${BASE_URL}/dashboard/combined-bmi-categories`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         }
//       );
//       if (response.ok) {
//         const data = await response.json();
//         setBmiData(data);
//       } else {
//         console.error("Failed to fetch BMI data");
//       }
//     } catch (error) {
//       console.error("Error fetching BMI data:", error);
//     }
//   };

//   useEffect(() => {
//     if (bmiChartRef.current && Object.keys(bmiData).length > 0) {
//       if (bmiChart) {
//         bmiChart.destroy();
//       }

//       bmiChart = new Chart(bmiChartRef.current, {
//         type: "bar",
//         data: {
//           labels: ["Underweight", "Normal", "Overweight", "Obese"],
//           datasets: [
//             {
//               label: "Male",
//               backgroundColor: "rgba(54, 162, 235, 0.5)",
//               borderColor: "rgba(54, 162, 235, 1)",
//               borderWidth: 1,
//               data: [
//                 bmiData.maleCategories.underweightCount,
//                 bmiData.maleCategories.normalCount,
//                 bmiData.maleCategories.overweightCount,
//                 bmiData.maleCategories.obeseCount,
//               ],
//             },
//             {
//               label: "Female",
//               backgroundColor: "rgba(255, 99, 132, 0.5)",
//               borderColor: "rgba(255, 99, 132, 1)",
//               borderWidth: 1,
//               data: [
//                 bmiData.femaleCategories.underweightCount,
//                 bmiData.femaleCategories.normalCount,
//                 bmiData.femaleCategories.overweightCount,
//                 bmiData.femaleCategories.obeseCount,
//               ],
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'No. of Users',
//               },
//             },
//           },
//         },
//       });
//     }

//     return () => {
//       if (bmiChart) {
//         bmiChart.destroy();
//       }
//     };
//   }, [bmiData]);








//   //=========================correct avg calories========================
  

  
//   useEffect(() => {
//     const fetchCalorieData = async () => {
//       const key = localStorage.getItem("jwtToken");
//       try {
//         const response = await fetch(`${BASE_URL}/dashboard/averageCalories`, {
//           headers: {
//             "Content-Type": "application/json",
//             Auth: `Bearer ${key}`,
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setCalorieData(data);
//         } else {
//           console.error('Failed to fetch calorie data');
//         }
//       } catch (error) {
//         console.error('Error fetching calorie data:', error);
//       }
//     };

//     fetchCalorieData();
//   }, []);

 
//   useEffect(() => {
//     let calorieChart;
  
//     if (calorieData.length > 0 && calorieChartRef.current) {
//       const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
//       const maleCalories = Array(ageGroups.length).fill(0);
//       const femaleCalories = Array(ageGroups.length).fill(0);
  
//       calorieData.forEach(entry => {
//         const index = ageGroups.indexOf(entry.ageGroup);
//         if (index !== -1) {
//           if (entry.gender === 'Male') {
//             maleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
//           } else if (entry.gender === 'Female') {
//             femaleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
//           }
//         }
//       });
  
//       calorieChart = new Chart(calorieChartRef.current, {
//         type: 'bar',
//         data: {
//           labels: ageGroups,
//           datasets: [
//             {
//               label: 'Male',
//               backgroundColor: 'rgba(54, 162, 235, 0.5)',
//               borderColor: 'rgba(54, 162, 235, 1)',
//               borderWidth: 1,
//               data: maleCalories,
//             },
//             {
//               label: 'Female',
//               backgroundColor: 'rgba(255, 99, 132, 0.5)',
//               borderColor: 'rgba(255, 99, 132, 1)',
//               borderWidth: 1,
//               data: femaleCalories,
//             },
//           ],
//         },
//         options: {
//           maintainAspectRatio: false,
//           aspectRatio: 2, // Set aspect ratio to fill available space
//           scales: {
//             y: {
//               beginAtZero: true,
//               title: {
//                 display: true,
//                 text: 'Average Calories in kcal',
//               },
//             },
//             x: {
//               title: {
//                 display: false, // Hide x-axis title
//                 text: '',
//               },
//             },
//           },
//           plugins: {
//             title: {
//               display: true,
//               text: 'Average Calories',
//               font: {
//                 size: 16,
//                 weight: 'bold',
//               },
//             },
//             tooltip: {
//               callbacks: {
//                 label: (context) => {
//                   const datasetIndex = context.datasetIndex;
//                   const ageGroup = context.label;
//                   const gender = datasetIndex === 0 ? 'Male' : 'Female';
//                   const entry = calorieData.find(item => item.ageGroup === ageGroup && item.gender === gender);
//                   if (entry) {
//                     return [
//                       `Gender: ${gender}`,
//                       `Average Calories: ${entry.averageCalories}`,
//                       `User Count: ${entry.userCount}`,
//                       `Active Users: ${entry.activeUsers}`,
//                       `Activity Count: ${entry.activityCount}`,
//                     ];
//                   }
//                 },
//               },
//             },
//             averageCalories: {
//               display: true,
//               text: 'Average Calories',
//               position: 'top',
//               align: 'start',
//               font: {
//                 size: 14,
//                 weight: 'bold',
//               },
//               color: '#333',
//               padding: 10,
//             },
//           },
//         },
//       });
//     }
  
//     return () => {
//       if (calorieChart) {
//         calorieChart.destroy();
//       }
//     };
//   }, [calorieData]);
  
  
//  //================================================fetch the data from api average sleep==============================================
  
// const fetchSleepData = async () => {
//   const key = localStorage.getItem("jwtToken");
//   try {
//     const response = await fetch(
//       `${BASE_URL}/dashboard/average-sleep`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Auth: `Bearer ${key}`,
//         },
//       }
//     );
//     if (response.ok) {
//       const data = await response.json();
//       setSleepData(data);
//     } else {
//       console.error("Failed to fetch sleep data");
//     }
//   } catch (error) {
//     console.error("Error fetching sleep data:", error);
//   }
// };

// useEffect(() => {
//   fetchSleepData();
// }, []);




// useEffect(() => {
//   if (!Array.isArray(sleepData) || sleepData.length === 0 || !sleepChartRef.current) return;

//   const ctx = sleepChartRef.current.getContext('2d');
//   let sleepChart;

//   if (sleepChartRef.current.chart) {
//     sleepChartRef.current.chart.destroy();
//   }

//   const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
//   const maleDurations = Array(labels.length).fill(0);
//   const femaleDurations = Array(labels.length).fill(0);

//   sleepData.forEach((entry) => {
//     const index = labels.indexOf(entry.ageGroup);
//     if (index !== -1) {
//       const durationInMinutes = parseFloat(entry.average.split('h:')[0]) * 60 + parseFloat(entry.average.split('h:')[1].replace('m', ''));
//       if (entry.gender === 'Male') {
//         maleDurations[index] = durationInMinutes;
//       } else if (entry.gender === 'Female') {
//         femaleDurations[index] = durationInMinutes;
//       }
//     }
//   });

//   // Set the width and height of the canvas element
//   sleepChartRef.current.width = sleepChartRef.current.parentNode.clientWidth;
//   sleepChartRef.current.height = sleepChartRef.current.parentNode.clientHeight;

//   const newChart = new Chart(ctx, {
//     type: 'bar',
//     data: {
//       labels: labels,
//       datasets: [{
//         label: 'Male',
//         data: maleDurations,
//         backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue color for male
//         borderColor: 'rgba(54, 162, 235, 1)',
//         borderWidth: 1
//       },
//       {
//         label: 'Female',
//         data: femaleDurations,
//         backgroundColor: 'rgba(255, 99, 132, 0.2)', // Pink color for female
//         borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1
//       }]
//     },
//     options: {
//       maintainAspectRatio: false,
//       aspectRatio: 2, // Aspect ratio set to 2:1
//       scales: {
//         y: {
//           beginAtZero: true,
//           title: {
//             display: true,
//             text: 'Duration in Minutes', // Updated title
//           }
//         },
//         x: {
//           title: {
//             display: false, // Set display to false to hide the x-axis title
//             text: ''
//           }
//         }
//       },
      
//       plugins: {
//         title: {
//           display: true,
//           text: 'Average Sleep Duration', // Updated title
//           font: {
//             size: 16,
//             weight: 'bold'
//           }
//         },
//         tooltip: {
//           callbacks: {
//             label: (context) => {
//               const datasetIndex = context.datasetIndex;
//               const ageGroup = context.label;
//               const gender = datasetIndex === 0 ? 'Male' : 'Female';
//               const entry = sleepData.find(item => item.ageGroup === ageGroup && item.gender === gender);
//               if (entry) {
//                 return [
//                   `Gender: ${gender}`,
//                   `Average Duration: ${entry.average}`,
//                   `User Count: ${entry.userCount}`,
//                   `Active Users: ${entry.activeUsers}`
//                 ];
//               }
//             }
//           }
//         }
//       }
//     }
//   });

//   // Store the chart instance in ref for later use
//   sleepChartRef.current.chart = newChart;
// }, [sleepData]);








// //=====================================return data on page====================================================================

//   return (
//     <div className="content">
//       <RedirectComponent />
//        <Navbar />
//       <div className="scrollableSidebar">
//         <Sidebar />
//       </div>

//       <Row>
          
          
//       <div className="widgets">
//           <Widget type="customer" />
//           <Widget type="order" />
//           <Widget type="earnings" />
//           <Widget type="balance" />
//         </div>
//       </Row>
//       <Row>
//         <Col lg="4">
//           <Card className="card-chart h-100">
//             <CardHeader>
//               {/* <h5 className="card-category">User Registration</h5> */}
//               <CardTitle tag="h4">User Registration Overview</CardTitle>
//             </CardHeader>
//             <CardBody>
//               <div className="chart-area">
//                 <canvas id="user-registration-chart" ref={chartRef} />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col lg="4">
//           <Card className="card-chart h-100">
//             <CardHeader>
//             {/* <h5 className="card-category">Age Distribution</h5> */}
//               <CardTitle tag="h4">Age Distribution Overview</CardTitle>
//             </CardHeader>
//             <CardBody>
//               <div className="chart-area">
//                 <canvas id="age-distribution-chart" ref={ageChartRef} />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
     
//       {/* New Row for BMI Data */}
      
//         <Col lg="4">
//           <Card className="card-chart h-100">
//             <CardHeader>
//              {/* <h5 className="card-category">BMI Distribution</h5> */}
//               <CardTitle tag="h4">BMI Distribution Overview</CardTitle>
//             </CardHeader>
//             <CardBody>
//               <div className="chart-area">
//                 <canvas id="bmi-distribution-chart" ref={bmiChartRef} />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//       <Row>
//       <Col lg="4">
//       <Card className="card-chart h-100">
//         <CardHeader>
//           <CardTitle tag="h4">Calories Distribution Overview</CardTitle>
//         </CardHeader>
//         <CardBody>
//           <div className="chart-area">
//             <canvas id="calories-distribution-chart" ref={calorieChartRef} />
//           </div>
//         </CardBody>
//       </Card>
//     </Col>
//         <Col lg="4">
//       <Card className="card-chart h-100">
//         <CardHeader>
//         <CardTitle tag="h4">Sleep Duration in the Last Week</CardTitle>

//         </CardHeader>
//         <CardBody>
//           <div className="chart-area">
//             <canvas id="sleep-distribution-chart" ref={sleepChartRef} />
//           </div>
//         </CardBody>
//       </Card>
//     </Col>







//     <Col lg="4">
//       <Card className="card-chart h-100">
//         <CardHeader>
//         <CardTitle tag="h4">Water Intake in the Last Week</CardTitle>
//         </CardHeader>
//         <CardBody>
//           <div className="chart-area">
//             <canvas id="water-intake-distribution-chart" ref={waterIntakeChartRef} />
//           </div>
//         </CardBody>
//       </Card>
//     </Col>

       
//       </Row>
//       <Row>
//         <Col lg="4">
//           <Card className="card-chart h-100">
//             <CardHeader>
//               {/* <h5 className="card-category">Gender Count</h5> */}
//               <CardTitle tag="h4">Gender Count Overview</CardTitle>
//             </CardHeader>
//             <CardBody>
//               <div className="chart-area">
//                 <canvas id="gender-count-chart" ref={genderCountChartRef} />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col lg="4">
//           <Card className="card-chart h-100">
//             <CardHeader>
//               {/* <h5 className="card-category">Work Level</h5> */}
//               <CardTitle tag="h4">Work Level Overview</CardTitle>
//             </CardHeader>
//             <CardBody>
//               <div className="chart-area">
//                 <canvas id="work-level-chart" ref={workLevelChartRef} />
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col lg="4">
//   <Card className="card-chart h-100">
//     <CardHeader>
//       {/* <h5 className="card-category">Steps Distribution</h5> */}
//       <CardTitle tag="h4">Steps in the Last Week</CardTitle>
//     </CardHeader>
//     <CardBody>
//       <div className="chart-area">
//         <canvas id="steps-distribution-chart" ref={stepsChartRef} />
//       </div>
//     </CardBody>
//   </Card>
// </Col>


//       </Row>
//       <Row>
//       <Col lg="4">
//   <Card className="card-chart h-100">
//     <CardHeader>
//       {/* <h5 className="card-category">Total Step Count and Kilometers</h5> */}
//       <CardTitle tag="h4">Step Count and Kilometers Overview</CardTitle>
//     </CardHeader>
//     <CardBody>
//       <div className="chart-area">
//         <canvas id="total-steps-kms-chart" ref={totalStepsKmsChartRef} />
//       </div>
//     </CardBody>
//   </Card>
// </Col>
// <Col lg="8"> {/* Specify lg="6" to occupy half of the row on large screens */}
//       <RatingsReviews/>
//     </Col>
//       </Row>
//       <Row>
//       <Col lg="12"> {/* Specify lg="6" to occupy half of the row on large screens */}
//       <Map/>
//     </Col>
//       </Row>
//     </div>
//   );
// }

// export default Dashboard;





//=================================import file ====================================================

import React, { useState, useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js/auto";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import "./home.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css"; // Add Bootstrap CSS
import RatingsReviews from "../../components/ratingsreviews/RatingsReviews";
import Map from "../../components/map/Map";
import Widget from "../../components/widget/Widget";
import Navbar from "../../components/navbar/Navbar";
import BASE_URL from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import 'chartjs-plugin-datalabels';

Chart.register(...registerables);
//===============================props=========================================================================

function Dashboard(props) {
  const [userData, setUserData] = useState({});
  const [ageData, setAgeData] = useState({});
  const [bmiData, setBmiData] = useState({});
  const [caloriesData, setCaloriesData] = useState([]);
  const [sleepData, setSleepData] = useState([]);
  const [waterIntakeData, setWaterIntakeData] = useState({});
  const [genderCountData, setGenderCountData] = useState({});
  const genderCountChartRef = useRef(null);
  const [workLevelData, setWorkLevelData] = useState({});
  const workLevelChartRef = useRef(null);
  const [stepsData, setStepsData] = useState([]);
  const stepsChartRef = useRef(null);
  const [totalStepCount, setTotalStepCount] = useState(0);
  const [totalKms, setTotalKms] = useState(0);
  

  const [userDataLoading, setUserDataLoading] = useState(true);
const [userDataError, setUserDataError] = useState(null);
const [ageDataLoading, setAgeDataLoading] = useState(true);
const [ageDataError, setAgeDataError] = useState(null);
const [bmiDataLoading, setBmiDataLoading] = useState(true);
const [bmiDataError, setBmiDataError] = useState(null);
const [sleepDataLoading, setSleepDataLoading] = useState(true);
const [sleepDataError, setSleepDataError] = useState(null);
const [calorieDataLoading, setCalorieDataLoading] = useState(true);
const [calorieDataError, setCalorieDataError] = useState(null);
const [waterIntakeDataLoading, setWaterIntakeDataLoading] = useState(true);
const [waterIntakeDataError, setWaterIntakeDataError] = useState(null);
const [genderCountDataLoading, setGenderCountDataLoading] = useState(true);
const [genderCountDataError, setGenderCountDataError] = useState(null);
const [workLevelDataLoading, setWorkLevelDataLoading] = useState(true);
const [workLevelDataError, setWorkLevelDataError] = useState(null);
const [stepsDataLoading, setStepsDataLoading] = useState(true);
const [stepsDataError, setStepsDataError] = useState(null);

  const [calorieData, setCalorieData] = useState([]); // Declare setCalorieData
  const calorieChartRef = useRef(null); // Declare calorieChartRef
   
  const chartRef = useRef(null);
  const ageChartRef = useRef(null);
  const bmiChartRef = useRef(null);
  const caloriesChartRef = useRef(null);
  const sleepChartRef = useRef(null);
  const waterIntakeChartRef = useRef(null);
  const totalStepsKmsChartRef = useRef(null);

let userRegistrationChart = null;
let ageChart = null;
let bmiChart = null;
let caloriesChart = null;
let sleepChart = null;
let waterIntakeChart = null;
let genderCountChart = null;
let workLevelChart = null;

//=====================================================hooks =====================================================
  useEffect(() => {
    fetchData();
    fetchAgeData();
    fetchBmiData();
    //fetchCaloriesData();
    fetchSleepData();
    // fetchWaterIntakeData(); 
    // fetchGenderCountData();
    fetchWorkLevelData();
    fetchStepsData();
    
    return () => {
      if (userRegistrationChart) {
        userRegistrationChart.destroy();
      }
      if (ageChart) {
        ageChart.destroy();
      }
      if (bmiChart) {
        bmiChart.destroy();
      }
      // if (caloriesChart) {
      //   caloriesChart.destroy();
      // }
      if (sleepChart) {
        sleepChart.destroy();
      }
   

    };
  }, []);




//================================================kilometres by gender====================================
  useEffect(() => {
    if (totalStepsKmsChartRef.current) {
      const totalStepsKmsChart = new Chart(totalStepsKmsChartRef.current, {
        type: "bar",
        data: {
          labels: ["Total Steps", "Total Kilometers"],
          datasets: [
            {
              label: "Total",
              backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
              borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
              borderWidth: 1,
              data: [totalStepCount, totalKms],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
        },
      });
  
      return () => {
        totalStepsKmsChart.destroy();
      };
    }
  }, [totalStepCount, totalKms]);
  
  useEffect(() => {
    const fetchTotalStepCountAndKms = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/dashboardDemo/totalStepCountAndKms`
        );
        if (response.ok) {
          const data = await response.json();
          setTotalStepCount(data.totalSteps);
          setTotalKms(data.totalKms);
        } else {
          console.error("Failed to fetch total step count and kilometers data");
        }
      } catch (error) {
        console.error("Error fetching total step count and kilometers data:", error);
      }
    };
  
    fetchTotalStepCountAndKms();
  }, []);
  //========================================================average steps==========================================================

  
  useEffect(() => {
    const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
    const maleSteps = Array(ageGroups.length).fill(0);
    const femaleSteps = Array(ageGroups.length).fill(0);
  
    if (stepsChartRef.current && stepsData.length > 0) {
      stepsData.forEach(entry => {
        const index = ageGroups.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'Male') {
            maleSteps[index] = parseFloat(entry.average);
          } else if (entry.gender === 'Female') {
            femaleSteps[index] = parseFloat(entry.average);
          }
        }
      });
  
      const stepsChart = new Chart(stepsChartRef.current, {
        type: 'bar',
        data: {
          labels: ageGroups,
          datasets: [
            {
              label: 'Male',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              data: maleSteps,
            },
            {
              label: 'Female',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              data: femaleSteps,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 2, // Set aspect ratio to fill available space
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No.of Average Steps',
              },
            },
            x: {
              title: {
                display: false, // Hide x-axis title
                text: '',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Steps',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'Male' : 'Female';
                  const entry = stepsData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Steps: ${entry.average}`,
                      `User Count: ${entry.userCount}`,
                      `Active Users: ${entry.activeUsers}`,
                    ];
                  }
                },
              },
            },
          },
        },
      });
  
      return () => {
        stepsChart.destroy();
      };
    }
  }, [stepsData]);
  
  

  const fetchStepsData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/average-steps-by-age`
      );
      if (response.ok) {
        const data = await response.json();
        setStepsData(data);
      } else {
        setStepsDataError("Failed to fetch steps data");
      }
    } catch (error) {
      setStepsDataError("Error fetching steps data: " + error.message);
    } finally {
      setStepsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchStepsData();
  }, []);


  //====================================worklevel================================================================


  useEffect(() => {
    let workLevelChart;
  
    if (Object.keys(workLevelData).length > 0 && workLevelChartRef.current) {
      if (workLevelChart) {
        workLevelChart.destroy();
      }
  
      const labels = Object.keys(workLevelData);
      const data = Object.values(workLevelData);
  
      workLevelChart = new Chart(workLevelChartRef.current, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(54, 162, 235, 0.5)",
                "rgba(255, 206, 86, 0.5)",
                "rgba(75, 192, 192, 0.5)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Number of Users by Work Level',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
        },
      });
    }
  
    return () => {
      if (workLevelChart) {
        workLevelChart.destroy();
      }
    };
  }, [workLevelData]);
  

  const fetchWorkLevelData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/dashboard/byWorkLevel`,
      );
      if (response.ok) {
        const data = await response.json();
        setWorkLevelData(data.usersByWorkLevel);
      } else {
        setWorkLevelDataError("Failed to fetch work level data");
      }
    } catch (error) {
      setWorkLevelDataError("Error fetching work level data: " + error.message);
    } finally {
      setWorkLevelDataLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkLevelData();
  }, []);
// ===================================================gender count ==============================================================
  useEffect(() => {
    if (genderCountChartRef.current && Object.keys(genderCountData).length > 0) {
      if (genderCountChart) {
        genderCountChart.destroy();
      }
  
      const labels = Object.keys(genderCountData);
      const data = Object.values(genderCountData);
  
      genderCountChart = new Chart(genderCountChartRef.current, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: ["rgba(54, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
              borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: 'Number of Users by Gender', // Set the title text
              font: {
                size: 16,
                weight: 'bold',
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
            },
          },
        },
      });
    }
  
    return () => {
      if (genderCountChart) {
        genderCountChart.destroy();
      }
    };
  }, [genderCountData]);
  

  const fetchGenderCountData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/gender-count`,
        {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setGenderCountData(data);
      } else {
        setGenderCountDataError("Failed to fetch gender count data");
      }
    } catch (error) {
      setGenderCountDataError("Error fetching gender count data: " + error.message);
    } finally {
      setGenderCountDataLoading(false);
    }
  };

  useEffect(() => {
    fetchGenderCountData();
  }, []);



  // ============================Render water intake distribution chart============================================
  
  
  const fetchWaterIntakeData = async () => {
    const key = localStorage.getItem("jwtToken");
    try {
      const response = await fetch(
        `${BASE_URL}/dashboard/average-water-intake`,
        {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setWaterIntakeData(data);
      } else {
        setWaterIntakeDataError("Failed to fetch water intake data");
      }
    } catch (error) {
      setWaterIntakeDataError("Error fetching water intake data: " + error.message);
    } finally {
      setWaterIntakeDataLoading(false);
    }
  };

  useEffect(() => {
    fetchWaterIntakeData();
  }, []);

  
  useEffect(() => {
    if (Object.keys(waterIntakeData).length > 0 && waterIntakeChartRef.current) {
      const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
      const maleIntake = Array(labels.length).fill(0);
      const femaleIntake = Array(labels.length).fill(0);
  
      if (waterIntakeChart) {
        waterIntakeChart.destroy();
      }
  
      waterIntakeData.forEach((entry) => {
        const index = labels.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'male') {
            maleIntake[index] = parseFloat(entry.average.split('ml')[0]);
          } else if (entry.gender === 'female') {
            femaleIntake[index] = parseFloat(entry.average.split('ml')[0]);
          }
        }
      });
  
      waterIntakeChart = new Chart(waterIntakeChartRef.current, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Male",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              data: maleIntake,
            },
            {
              label: "Female",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
              data: femaleIntake,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 2, // Aspect ratio set to 2:1
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Intake in ml', // Updated title
              },
            },
            x: {
              title: {
                display: false, // Remove x-axis title
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += tooltipItem.yLabel.toLocaleString() + ' ml';
                return label;
              }
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Water Intake in ml',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'male' : 'female';
                  const entry = waterIntakeData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Intake: ${entry.average}`,
                      `User Count: ${entry.userCount}`
                    ];
                  }
                }
              }
            }
          }
        },
      });
    }
  
    return () => {
      if (waterIntakeChart) {
        waterIntakeChart.destroy();
      }
    };
  }, [waterIntakeData]);
  

  // ========================================Chart rendering for User Registration=================================================
  
  //===================================user by months======================================================
 
  const fetchData = async () => {
    setUserDataLoading(true);
    const key = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${BASE_URL}/dashboard/user-registration-by-month?year=2024`, {
            headers: {
                "Content-Type": "application/json",
                Auth: `Bearer ${key}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setUserData(data);
        } else {
            setUserDataError("Failed to fetch user registration data");
        }
    } catch (error) {
        setUserDataError("Error fetching user registration data: " + error.message);
    } finally {
        setUserDataLoading(false);
    }
};

  useEffect(() => {
    if (chartRef.current && Object.keys(userData).length > 0) {
      if (userRegistrationChart) {
        userRegistrationChart.destroy();
      }

      userRegistrationChart = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: Object.keys(userData),
          datasets: [
            {
              label: "Registered Users",
              fill: false,
              lineTension: 0.1,
              backgroundColor: "rgba(75,192,192,0.4)",
              borderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointBorderColor: "rgba(75,192,192,1)",
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              data: Object.values(userData),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No.of Users by Months',
                
               
              },
            },
            x: {
              title: {
                display: true,
                
                
              },
              ticks: {
                autoSkip: false,
                fontSize: 12,
              },
              gridLines: {
                display: false,
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#333",
            },
          },
        },
      });
    }

    return () => {
      if (userRegistrationChart) {
        userRegistrationChart.destroy();
      }
    };
  }, [userData]);




  //================================================== Chart rendering for Age ============================================
  //====================================================fetch age data===============================================
  const fetchAgeData = async () => {
    setAgeDataLoading(true);
    const key = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${BASE_URL}/dashboard/age-categories`, {
            headers: {
                "Content-Type": "application/json",
                Auth: `Bearer ${key}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setAgeData(data);
        } else {
            setAgeDataError("Failed to fetch age data");
        }
    } catch (error) {
        setAgeDataError("Error fetching age data: " + error.message);
    } finally {
        setAgeDataLoading(false);
    }
};
  useEffect(() => {
    if (ageChartRef.current && Object.keys(ageData).length > 0) {
      if (ageChart) {
        ageChart.destroy();
      }

      ageChart = new Chart(ageChartRef.current, {
        type: "bar",
        data: {
          labels: Object.keys(ageData),
          datasets: [
            {
              label: "Age Distribution",
              backgroundColor: [
                "rgba(255, 206, 86, 0.5)",
                "rgba(54, 162, 235, 0.5)",
                "rgba(75, 192, 192, 0.5)",
                "rgba(153, 102, 255, 0.5)",
                "rgba(255, 159, 64, 0.5)",
              ],
              borderColor: [
                "rgba(255, 206, 86, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
              data: Object.values(ageData),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No. of Users by Age',
              },
            },
          },
        },
      });
    }

    return () => {
      if (ageChart) {
        ageChart.destroy();
      }
    };
  }, [ageData]);

  // =============================================Chart rendering for BMI Distribution==================================================
  ///========================================fetch data by bmi================================================
  const fetchBmiData = async () => {
    setBmiDataLoading(true);
    const key = localStorage.getItem("jwtToken");
    try {
        const response = await fetch(`${BASE_URL}/dashboard/combined-bmi-categories`, {
            headers: {
                "Content-Type": "application/json",
                Auth: `Bearer ${key}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setBmiData(data);
        } else {
            setBmiDataError("Failed to fetch BMI data");
        }
    } catch (error) {
        setBmiDataError("Error fetching BMI data: " + error.message);
    } finally {
        setBmiDataLoading(false);
    }
};


  useEffect(() => {
    if (bmiChartRef.current && Object.keys(bmiData).length > 0) {
      if (bmiChart) {
        bmiChart.destroy();
      }

      bmiChart = new Chart(bmiChartRef.current, {
        type: "bar",
        data: {
          labels: ["Underweight", "Normal", "Overweight", "Obese"],
          datasets: [
            {
              label: "Male",
              backgroundColor: "rgba(54, 162, 235, 0.5)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              data: [
                bmiData.maleCategories.underweightCount,
                bmiData.maleCategories.normalCount,
                bmiData.maleCategories.overweightCount,
                bmiData.maleCategories.obeseCount,
              ],
            },
            {
              label: "Female",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
              borderColor: "rgba(255, 99, 132, 1)",
              borderWidth: 1,
              data: [
                bmiData.femaleCategories.underweightCount,
                bmiData.femaleCategories.normalCount,
                bmiData.femaleCategories.overweightCount,
                bmiData.femaleCategories.obeseCount,
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'No. of Users',
              },
            },
          },
        },
      });
    }

    return () => {
      if (bmiChart) {
        bmiChart.destroy();
      }
    };
  }, [bmiData]);








  //=========================correct avg calories========================
  

  useEffect(() => {
    const fetchCalorieData = async () => {
      const key = localStorage.getItem("jwtToken");
      try {
        const response = await fetch(`${BASE_URL}/dashboard/averageCalories`, {
          headers: {
            "Content-Type": "application/json",
            Auth: `Bearer ${key}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCalorieData(data);
        } else {
          setCalorieDataError('Failed to fetch calorie data');
        }
      } catch (error) {
        setCalorieDataError('Error fetching calorie data: ' + error.message);
      } finally {
        setCalorieDataLoading(false);
      }
    };

    fetchCalorieData();
  }, []);
 
  useEffect(() => {
    let calorieChart;
  
    if (calorieData.length > 0 && calorieChartRef.current) {
      const ageGroups = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
      const maleCalories = Array(ageGroups.length).fill(0);
      const femaleCalories = Array(ageGroups.length).fill(0);
  
      calorieData.forEach(entry => {
        const index = ageGroups.indexOf(entry.ageGroup);
        if (index !== -1) {
          if (entry.gender === 'Male') {
            maleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
          } else if (entry.gender === 'Female') {
            femaleCalories[index] = parseFloat(entry.averageCalories.split('kcal')[0]);
          }
        }
      });
  
      calorieChart = new Chart(calorieChartRef.current, {
        type: 'bar',
        data: {
          labels: ageGroups,
          datasets: [
            {
              label: 'Male',
              backgroundColor: 'rgba(54, 162, 235, 0.5)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              data: maleCalories,
            },
            {
              label: 'Female',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              data: femaleCalories,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          aspectRatio: 2, // Set aspect ratio to fill available space
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Average Calories in kcal',
              },
            },
            x: {
              title: {
                display: false, // Hide x-axis title
                text: '',
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: 'Average Calories',
              font: {
                size: 16,
                weight: 'bold',
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const datasetIndex = context.datasetIndex;
                  const ageGroup = context.label;
                  const gender = datasetIndex === 0 ? 'Male' : 'Female';
                  const entry = calorieData.find(item => item.ageGroup === ageGroup && item.gender === gender);
                  if (entry) {
                    return [
                      `Gender: ${gender}`,
                      `Average Calories: ${entry.averageCalories}`,
                      `User Count: ${entry.userCount}`,
                      `Active Users: ${entry.activeUsers}`,
                      `Activity Count: ${entry.activityCount}`,
                    ];
                  }
                },
              },
            },
            averageCalories: {
              display: true,
              text: 'Average Calories',
              position: 'top',
              align: 'start',
              font: {
                size: 14,
                weight: 'bold',
              },
              color: '#333',
              padding: 10,
            },
          },
        },
      });
    }
  
    return () => {
      if (calorieChart) {
        calorieChart.destroy();
      }
    };
  }, [calorieData]);
  
  
 //================================================fetch the data from api average sleep==============================================
  
 const fetchSleepData = async () => {
  const key = localStorage.getItem("jwtToken");
  try {
    const response = await fetch(
      `${BASE_URL}/dashboard/average-sleep`,
      {
        headers: {
          "Content-Type": "application/json",
          Auth: `Bearer ${key}`,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      setSleepData(data);
    } else {
      setSleepDataError("Failed to fetch sleep data");
    }
  } catch (error) {
    setSleepDataError("Error fetching sleep data: " + error.message);
  } finally {
    setSleepDataLoading(false);
  }
};

useEffect(() => {
  fetchSleepData();
}, []);




useEffect(() => {
  if (!Array.isArray(sleepData) || sleepData.length === 0 || !sleepChartRef.current) return;

  const ctx = sleepChartRef.current.getContext('2d');
  let sleepChart;

  if (sleepChartRef.current.chart) {
    sleepChartRef.current.chart.destroy();
  }

  const labels = ['<15yrs', '15-29yrs', '30-44yrs', '45-59yrs', '>60yrs'];
  const maleDurations = Array(labels.length).fill(0);
  const femaleDurations = Array(labels.length).fill(0);

  sleepData.forEach((entry) => {
    const index = labels.indexOf(entry.ageGroup);
    if (index !== -1) {
      const durationInMinutes = parseFloat(entry.average.split('h:')[0]) * 60 + parseFloat(entry.average.split('h:')[1].replace('m', ''));
      if (entry.gender === 'Male') {
        maleDurations[index] = durationInMinutes;
      } else if (entry.gender === 'Female') {
        femaleDurations[index] = durationInMinutes;
      }
    }
  });

  // Set the width and height of the canvas element
  sleepChartRef.current.width = sleepChartRef.current.parentNode.clientWidth;
  sleepChartRef.current.height = sleepChartRef.current.parentNode.clientHeight;

  const newChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Male',
        data: maleDurations,
        backgroundColor: 'rgba(54, 162, 235, 0.5)', // Blue color for male
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1
      },
      {
        label: 'Female',
        data: femaleDurations,
        backgroundColor: 'rgba(255, 99, 132, 0.2)', // Pink color for female
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }]
    },
    options: {
      maintainAspectRatio: false,
      aspectRatio: 2, // Aspect ratio set to 2:1
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Duration in Minutes', // Updated title
          }
        },
        x: {
          title: {
            display: false, // Set display to false to hide the x-axis title
            text: ''
          }
        }
      },
      
      plugins: {
        title: {
          display: true,
          text: 'Average Sleep Duration', // Updated title
          font: {
            size: 16,
            weight: 'bold'
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const datasetIndex = context.datasetIndex;
              const ageGroup = context.label;
              const gender = datasetIndex === 0 ? 'Male' : 'Female';
              const entry = sleepData.find(item => item.ageGroup === ageGroup && item.gender === gender);
              if (entry) {
                return [
                  `Gender: ${gender}`,
                  `Average Duration: ${entry.average}`,
                  `User Count: ${entry.userCount}`,
                  `Active Users: ${entry.activeUsers}`
                ];
              }
            }
          }
        }
      }
    }
  });

  // Store the chart instance in ref for later use
  sleepChartRef.current.chart = newChart;
}, [sleepData]);








//=====================================return data on page====================================================================

  return (
    <div className="content">
      <RedirectComponent />
       <Navbar />
      <div className="scrollableSidebar">
        <Sidebar />
      </div>

      <Row>
          
          
      <div className="widgets">
          <Widget type="customer" />
          <Widget type="order" />
          <Widget type="earnings" />
          <Widget type="balance" />
        </div>
      </Row>
      <Row>
      <Col lg="4">
    <Card className="card-chart h-100">
      <CardHeader>
        {/* <h5 className="card-category">User Registration</h5> */}
        <CardTitle tag="h4">User Registration Overview</CardTitle>
      </CardHeader>
      <CardBody>
      <div className="chart-area">
  {userDataLoading && <p>Loading user registration data...</p>}
  {userDataError && <p>Error: {userDataError}</p>}
  {!userDataLoading && !userDataError && (
    <canvas id="user-registration-chart" ref={chartRef} />
  )}
</div>
      </CardBody>
    </Card>
  </Col>
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
            {/* <h5 className="card-category">Age Distribution</h5> */}
              <CardTitle tag="h4">Age Distribution Overview</CardTitle>
            </CardHeader>
            <CardBody>
            <div className="chart-area">
  {ageDataLoading && <p>Loading age distribution data...</p>}
  {ageDataError && <p>Error: {ageDataError}</p>}
  {!ageDataLoading && !ageDataError && (
    <canvas id="age-distribution-chart" ref={ageChartRef} />
  )}
</div>

            </CardBody>
          </Card>
        </Col>
     
      {/* New Row for BMI Data */}
      
        <Col lg="4">
          <Card className="card-chart h-100">
            <CardHeader>
             {/* <h5 className="card-category">BMI Distribution</h5> */}
              <CardTitle tag="h4">BMI Distribution Overview</CardTitle>
            </CardHeader>
            <CardBody>
            <div className="chart-area">
  {bmiDataLoading && <p>Loading BMI distribution data...</p>}
  {bmiDataError && <p>Error: {bmiDataError}</p>}
  {!bmiDataLoading && !bmiDataError && (
    <canvas id="bmi-distribution-chart" ref={bmiChartRef} />
  )}
</div>

            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* //======================================================================calorieChart========================================== */}
     
    <Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Calories Distribution Overview</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {calorieDataLoading && <p>Loading calorie data...</p>}
              {calorieDataError && <p>Error: {calorieDataError}</p>}
              {!calorieDataLoading && !calorieDataError && (
                <canvas id="calories-distribution-chart" ref={calorieChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    {/* //==============================================================================sleepchart============================== */}
    <Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Sleep Duration in the Last Week</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {sleepDataLoading && <p>Loading sleep data...</p>}
              {sleepDataError && <p>Error: {sleepDataError}</p>}
              {!sleepDataLoading && !sleepDataError && (
                <canvas id="sleep-distribution-chart" ref={sleepChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    




{/* //=====================================waterchart======================================================= */}
<Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Water Intake in the Last Week</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {waterIntakeDataLoading && <p>Loading water intake data...</p>}
              {waterIntakeDataError && <p>Error: {waterIntakeDataError}</p>}
              {!waterIntakeDataLoading && !waterIntakeDataError && (
                <canvas id="water-intake-distribution-chart" ref={waterIntakeChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>

       {/* //========================================gendercount======================================== */}
      </Row>
      <Row>
      <Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Gender Count Overview</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {genderCountDataLoading && <p>Loading gender count data...</p>}
              {genderCountDataError && <p>Error: {genderCountDataError}</p>}
              {!genderCountDataLoading && !genderCountDataError && (
                <canvas id="gender-count-chart" ref={genderCountChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      {/* ====================================================worklevel================================== */}
      <Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Work Level Overview</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {workLevelDataLoading && <p>Loading work level data...</p>}
              {workLevelDataError && <p>Error: {workLevelDataError}</p>}
              {!workLevelDataLoading && !workLevelDataError && (
                <canvas id="work-level-chart" ref={workLevelChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
        {/* ========================================================================================steps==================== */}
        <Col lg="4">
        <Card className="card-chart h-100">
          <CardHeader>
            <CardTitle tag="h4">Steps in the Last Week</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="chart-area">
              {stepsDataLoading && <p>Loading steps data...</p>}
              {stepsDataError && <p>Error: {stepsDataError}</p>}
              {!stepsDataLoading && !stepsDataError && (
                <canvas id="steps-distribution-chart" ref={stepsChartRef} />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>

      </Row>
      <Row>
      <Col lg="4">
  <Card className="card-chart h-100">
    <CardHeader>
      {/* <h5 className="card-category">Total Step Count and Kilometers</h5> */}
      <CardTitle tag="h4">Step Count and Kilometers Overview</CardTitle>
    </CardHeader>
    <CardBody>
      <div className="chart-area">
        <canvas id="total-steps-kms-chart" ref={totalStepsKmsChartRef} />
      </div>
    </CardBody>
  </Card>
</Col>
<Col lg="8"> {/* Specify lg="6" to occupy half of the row on large screens */}
      <RatingsReviews/>
    </Col>
      </Row>
      <Row>
      <Col lg="12"> {/* Specify lg="6" to occupy half of the row on large screens */}
      <Map/>
    </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
