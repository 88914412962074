// import React, { useState, useEffect } from 'react';
// import Sidebar from '../../components/sidebar/Sidebar';

// const OrderList = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await fetch('http://localhost:7073/dashboard/orders');
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setOrders(data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchOrders();
//   }, []);

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <div style={styles.container}>
//       <Sidebar />
//       <div style={styles.content}>
//         <h2>Orders</h2>
//         <ul>
//           {orders.map(order => (
//             <li key={order.paymentId}>
//               <p>Name: {order.name}</p>
//               <p>Email: {order.email}</p>
//               <p>Book Title: {order.bookTitle}</p>
//               <p>Quantity: {order.quantity}</p>
//               <p>Amount: {order.amount}</p>
//               <p>Payment ID: {order.paymentId}</p>
//               <p>Delivery Address: {order.deliveryAddress}</p>
//               <p>Delivery Date: {order.deliveryDate}</p>
//               <p>Contact: {order.contact}</p>
//               <p>Delivery Status: {order.deliveryStatus}</p>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//   },
//   content: {
//     flex: 1,
//     marginLeft: '250px', // Adjust according to your sidebar width
//     padding: '20px',
//   },
// };

// export default OrderList;


// import React, { useState, useEffect } from 'react';
// import Sidebar from '../../components/sidebar/Sidebar';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { BASE_URL } from '../../components/config/apiConfig';
// const OrderList = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedDate, setSelectedDate] = useState(null);

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}/dashboard/orders`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setOrders(data);
//         setLoading(false);
//       } catch (error) {
//         setError(error);
//         setLoading(false);
//       }
//     };

//     fetchOrders();
//   }, []);

//   const filterOrdersByDate = (date) => {
//     setSelectedDate(date);
//   };

//   // const filteredOrders = selectedDate ? orders.filter(order => new Date(order.date).toDateString() === selectedDate.toDateString()) : orders;
//   const filteredOrders = selectedDate ? orders.filter(order => new Date(order.deliveryDate).toDateString() === selectedDate.toDateString()) : [] || [];

//   const handleDeliveryStatusChange = async (orderId, newStatus) => {
//     try {
//       const response = await fetch(`${BASE_URL}/dashboard/orders/updateDeliveryStatus?orderId=${orderId}&newStatus=${newStatus}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       });
//       if (!response.ok) {
//         throw new Error('Failed to update delivery status');
//       }
//       // Update the orders list after successful status update
//       const updatedOrders = orders.map(order => {
//         if (order.orderId === orderId) {
//           return { ...order, deliveryStatus: newStatus };
//         }
//         return order;
//       });
//       setOrders(updatedOrders);
//     } catch (error) {
//       console.error('Error updating delivery status:', error);
//     }
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   const CustomInput = ({ value, onClick }) => (
//     <div style={styles.customInput}>
//       <input
//         type="text"
//         value={value}
//         onClick={onClick}
//         readOnly
//         style={styles.dateInput}
//       />
//       <FaCalendarAlt style={styles.calendarIcon} onClick={onClick} />
//     </div>
//   );

//   return (
//     <div style={styles.container}>
//       <Sidebar />
//       <div style={styles.content}>
//         <h2 style={styles.heading}>Orders</h2>
//         <div style={styles.datePickerContainer}>
//           <label style={styles.label}></label>
//           <DatePicker
//             selected={selectedDate}
//             onChange={filterOrdersByDate}
//             dateFormat="MM/dd/yyyy"
//             customInput={<CustomInput />}
//             style={styles.datePicker}
//           />
//         </div>
//         <div style={styles.orderContainer}>
//           {filteredOrders.map((order, index) => (
//             <div key={order.orderId} style={styles.card}>
//               <div style={styles.cardBody}>
//                 <h3 style={styles.cardTitle}>Order Details</h3>
//                 <p><span style={styles.label}>Name:</span> <span style={styles.value}>{order.name}</span></p>
//                 <p><span style={styles.label}>Email:</span> <span style={styles.value}>{order.email}</span></p>
//                 <p><span style={styles.label}>Book Title:</span> <span style={styles.value}>{order.bookTitle}</span></p>
//                 <p><span style={styles.label}>Quantity:</span> <span style={styles.value}>{order.quantity}</span></p>
//                 <p><span style={styles.label}>Amount:</span> <span style={styles.value}>{(order.amount/100)}</span></p>
//                 <p><span style={styles.label}>Payment ID:</span> <span style={styles.value}>{order.paymentId}</span></p>
//                 <p><span style={styles.label}>Delivery Address:</span> <span style={styles.value}>{order.deliveryAddress}</span></p>
//                 <p><span style={styles.label}>Delivery Date:</span> <span style={styles.value}>{order.deliveryDate}</span></p>
//                 <div>
//                   <label style={styles.label}>Delivery Status:</label>
//                   <select value={order.deliveryStatus} onChange={(e) => handleDeliveryStatusChange(order.orderId, e.target.value)} style={styles.statusSelect}>
//                     <option value="Pending">Pending</option>
//                     <option value="Canceled">Canceled</option>
//                     <option value="Delivered">Delivered</option>
//                   </select>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     backgroundColor: '#f0f2f5',
//     minHeight: '100vh',
//   },
//   content: {
//     flex: 1,
//     padding: '20px',
//     marginLeft: '250px', // Adjust according to your sidebar width
//   },
//   heading: {
//     fontSize: '24px',
//     marginBottom: '20px',
//     color: 'black', // White color for text
//     textAlign: 'center', // Center align the text
//     backgroundColor: '#e0e0e0', // Dark gray background color
//     padding: '10px', // Add padding for better readability
//     borderRadius: '5px', // Rounded corners
//   },
//   datePickerContainer: {
//     marginBottom: '20px',
//   },
//   label: {
//     fontWeight: 'bold',
//     color: '#333',
//   },
//   value: {
//     color: '#666',
//   },
//   customInput: {
//     display: 'flex',
//     alignItems: 'center',
//   },
//   calendarIcon: {
//     marginLeft: '5px',
//     cursor: 'pointer',
//   },
//   datePicker: {
//     border: '1px solid #ccc',
//     borderRadius: '5px',
//     padding: '8px',
//     marginRight: '5px',
//     width: '150px',
//   },
//   orderContainer: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '20px',
//   },
//   card: {
//     backgroundColor: '#fff',
//     borderRadius: '10px',
//     padding: '20px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     flex: '1 1 300px',
//     minWidth: '300px',
//   },
//   cardTitle: {
//     fontSize: '20px',
//     marginBottom: '10px',
//     color: 'white', // White color for text
//     textAlign: 'center', // Center align the text
//     backgroundColor: '#666', // Gray background color
//     padding: '10px', // Add padding for better readability
//     borderRadius: '5px', // Rounded corners
//   },
//   cardBody: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '10px',
//   },
//   statusSelect: {
//     width: '100%',
//     padding: '8px',
//     marginTop: '5px',
//     border: '1px solid #ccc',
//     borderRadius: '5px',
//   },
// };

// export default OrderList;


import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { BASE_URL } from '../../components/config/apiConfig';

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${BASE_URL}/dashboard/orders`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setOrders(data.orders); // assuming data contains orders array
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filterOrdersByDate = (date) => {
    setSelectedDate(date);
  };

  const filteredOrders = selectedDate ? orders.filter(order => new Date(order.deliveryDate).toDateString() === selectedDate.toDateString()) : orders;

  const handleDeliveryStatusChange = async (orderId, newStatus) => {
    try {
      const response = await fetch(`${BASE_URL}/dashboard/orders/updateDeliveryStatus?orderId=${orderId}&newStatus=${newStatus}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to update delivery status');
      }
      // Update the orders list after successful status update
      const updatedOrders = orders.map(order => {
        if (order.orderId === orderId) {
          return { ...order, deliveryStatus: newStatus };
        }
        return order;
      });
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating delivery status:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const CustomInput = ({ value, onClick }) => (
    <div style={styles.customInput}>
      <input
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={styles.dateInput}
      />
      <FaCalendarAlt style={styles.calendarIcon} onClick={onClick} />
    </div>
  );

  return (
    <div style={styles.container}>
      <Sidebar />
      <div style={styles.content}>
        <h2 style={styles.heading}>Orders</h2>
        <div style={styles.datePickerContainer}>
          <label style={styles.label}>Select Date:</label> {/* added label text */}
          <DatePicker
            selected={selectedDate}
            onChange={filterOrdersByDate}
            dateFormat="MM/dd/yyyy"
            customInput={<CustomInput />}
            style={styles.datePicker}
          />
        </div>
        <div style={styles.orderContainer}>
          {filteredOrders.map((order, index) => (
            <div key={order.orderId} style={styles.card}>
              <div style={styles.cardBody}>
                <h3 style={styles.cardTitle}>Order Details</h3>
                <p><span style={styles.label}>Name:</span> <span style={styles.value}>{order.name}</span></p>
                <p><span style={styles.label}>Email:</span> <span style={styles.value}>{order.email}</span></p>
                <p><span style={styles.label}>Book Title:</span> <span style={styles.value}>{order.bookTitle}</span></p>
                <p><span style={styles.label}>Quantity:</span> <span style={styles.value}>{order.quantity}</span></p>
                <p><span style={styles.label}>Amount:</span> <span style={styles.value}>{(order.amount/100)}</span></p>
                <p><span style={styles.label}>Payment ID:</span> <span style={styles.value}>{order.paymentId}</span></p>
                <p><span style={styles.label}>Delivery Address:</span> <span style={styles.value}>{order.deliveryAddress}</span></p>
                <p><span style={styles.label}>Delivery Date:</span> <span style={styles.value}>{order.deliveryDate}</span></p>
                <div>
                  <label style={styles.label}>Delivery Status:</label>
                  <select value={order.deliveryStatus} onChange={(e) => handleDeliveryStatusChange(order.orderId, e.target.value)} style={styles.statusSelect}>
                    <option value="Pending">Pending</option>
                    <option value="Canceled">Canceled</option>
                    <option value="Delivered">Delivered</option>
                  </select>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
  },
  content: {
    flex: 1,
    padding: '20px',
    marginLeft: '250px', // Adjust according to your sidebar width
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: 'black', // White color for text
    textAlign: 'center', // Center align the text
    backgroundColor: '#e0e0e0', // Dark gray background color
    padding: '10px', // Add padding for better readability
    borderRadius: '5px', // Rounded corners
  },
  datePickerContainer: {
    marginBottom: '20px',
  },
  label: {
    fontWeight: 'bold',
    color: '#333',
  },
  value: {
    color: '#666',
  },
  customInput: {
    display: 'flex',
    alignItems: 'center',
  },
  calendarIcon: {
    marginLeft: '5px',
    cursor: 'pointer',
  },
  datePicker: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginRight: '5px',
    width: '150px',
  },
  orderContainer: {
    display: 'flex',
    flexWrap: 'wrap',
   // gap: '20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    flex: '1 1 300px',
    minWidth: '300px',
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: '10px',
    color: 'white', // White color for text
    textAlign: 'center', // Center align the text
    backgroundColor: '#666', // Gray background color
    padding: '10px', // Add padding for better readability
    borderRadius: '5px', // Rounded corners
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
   // gap: '10px',
  },
  statusSelect: {
    width: '100%',
    padding: '8px',
    marginTop: '5px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
};

export default OrderList;
