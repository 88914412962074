
// import React, { useEffect, useRef, useState } from 'react';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import './map.scss';
// import BASE_URL from '../config/apiConfig';

// const Map = () => {
//     const mapContainerRef = useRef(null);
//     const [selectedState, setSelectedState] = useState(null);
//     const [usersData, setUsersData] = useState([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const key = localStorage.getItem('jwtToken');
//                 const response = await fetch(`${BASE_URL}/dashboard/user/state-count`, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Auth': `Bearer ${key}`,
//                     },
//                 });
//                 const data = await response.json();
//                 const sortedData = Object.entries(data).sort((a, b) => {
//                     if (a[0] === 'No Access') return 1;
//                     if (b[0] === 'No Access') return -1;
//                     return b[1] - a[1];
//                 });
//                 setUsersData(sortedData);
//             } catch (error) {
//                 console.error('Error fetching user data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         if (mapContainerRef.current && !mapContainerRef.current._leaflet_id) {
//             const map = L.map(mapContainerRef.current).setView([20.5937, 78.9629], 5);

//             L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//                 attribution: '&copy; OpenStreetMap contributors'
//             }).addTo(map);

//             map.on('click', async (e) => {
//                 const latlng = e.latlng;
//                 const key = localStorage.getItem('jwtToken');
//                 try {
//                     const response = await fetch(`${BASE_URL}/dashboard/user/state-count?lat=${latlng.lat}&lng=${latlng.lng}`, {
//                         headers: {
//                             'Content-Type': 'application/json',
//                             'Auth': `Bearer ${key}`,
//                         },
//                     });
//                     const data = await response.json();
//                     setSelectedState(data.state);
//                 } catch (error) {
//                     console.error('Error fetching state data:', error);
//                 }
//             });

//             // Fetch GeoJSON data for Indian states
//             fetch('/india_states.json') // assuming the file is located at public/india_states.json
//                 .then(response => response.json())
//                 .then(geojsonData => {
//                     L.geoJSON(geojsonData, {
//                         onEachFeature: (feature, layer) => {
//                             layer.on({
//                                 mouseover: (e) => {
//                                     const stateName = e.target.feature.properties.NAME_1;
//                                     e.target.bindTooltip(stateName).openTooltip();
//                                     setSelectedState(stateName);
//                                 },
//                                 mouseout: (e) => {
//                                     e.target.closeTooltip();
//                                     setSelectedState(null);
//                                 },
//                                 click: (e) => {
//                                     const stateName = e.target.feature.properties.NAME_1;
//                                     fetchStateData(stateName);
//                                 }
//                             });
//                         }
//                     }).addTo(map); // Render GeoJSON data on map
//                 })
//                 .catch(error => {
//                     console.error('Error fetching GeoJSON data:', error);
//                 });
//         }
//     }, []); // Render map only once when component mounts

//     const fetchStateData = async (stateName) => {
//         try {
//             const key = localStorage.getItem('jwtToken');
//             const response = await fetch(`${BASE_URL}/dashboard/user/state-count?state=${stateName}`, {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Auth': `Bearer ${key}`,
//                 },
//             });
//             const data = await response.json();
//             setSelectedState(data.state);
//         } catch (error) {
//             console.error('Error fetching state data:', error);
//         }
//     };

//     return (
//         <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
//             <div className='col-lg-9' style={{ paddingRight: '20px', marginTop: '10px' }}> {/* Added marginTop */}
//                 <div className='card' style={{ height: '100%' }}>
//                     <div className="calorie-consumption">
//                         <h4 className='card-header pb-0'>Map</h4>
//                         <div className='card-body' style={{ height: '400px' }}>
//                             <div ref={mapContainerRef} id="indiaMap" style={{ height: '100%', width: '100%' }}></div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
    
//             <div className='col-lg-3'>
//                 <div className='card' style={{ height: '100%' }}>
//                     <div className='card-body'>
//                         <h4>{selectedState || ''}</h4>
//                         <table className="table">
//                             <thead>
//                                 <tr>
//                                     <th>State</th>
//                                     <th>No. of Users</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {usersData.map(([state, users], index) => (
//                                     <tr key={state} style={{ backgroundColor: index === usersData.length - 1 ? 'lightgray' : 'transparent' }}>
//                                         <td>{state}</td>
//                                         <td>{state === 'No Access' ? <span style={{ color: 'orange', fontWeight: 'bold' }}>{users}</span> : <span style={{ fontWeight: 'bold' }}>{users}</span>}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Map;
import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './map.scss';
import BASE_URL from '../config/apiConfig';

const Map = () => {
    const mapContainerRef = useRef(null);
    const [selectedState, setSelectedState] = useState(null);
    const [usersData, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const key = localStorage.getItem('jwtToken');
                const response = await fetch(`${BASE_URL}/dashboard/user/state-count`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Auth': `Bearer ${key}`,
                    },
                });
                const data = await response.json();
                const sortedData = Object.entries(data).sort((a, b) => {
                    if (a[0] === 'No Access') return 1;
                    if (b[0] === 'No Access') return -1;
                    return b[1] - a[1];
                });
                setUsersData(sortedData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (mapContainerRef.current && !mapContainerRef.current._leaflet_id) {
            const map = L.map(mapContainerRef.current).setView([20.5937, 78.9629], 5);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; OpenStreetMap contributors'
            }).addTo(map);

            map.on('click', async (e) => {
                const latlng = e.latlng;
                const key = localStorage.getItem('jwtToken');
                try {
                    const response = await fetch(`${BASE_URL}/dashboard/user/state-count?lat=${latlng.lat}&lng=${latlng.lng}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Auth': `Bearer ${key}`,
                        },
                    });
                    const data = await response.json();
                    setSelectedState(data.state);
                } catch (error) {
                    console.error('Error fetching state data:', error);
                }
            });

            // Fetch GeoJSON data for Indian states
            fetch('/india_states.json') // assuming the file is located at public/india_states.json
                .then(response => response.json())
                .then(geojsonData => {
                    L.geoJSON(geojsonData, {
                        onEachFeature: (feature, layer) => {
                            layer.on({
                                mouseover: (e) => {
                                    const stateName = e.target.feature.properties.NAME_1;
                                    e.target.bindTooltip(stateName).openTooltip();
                                    fetchStateData(stateName); // Fetch user data for the hovered state
                                },
                                mouseout: (e) => {
                                    e.target.closeTooltip();
                                    setSelectedState(null);
                                },
                                click: (e) => {
                                    const stateName = e.target.feature.properties.NAME_1;
                                    fetchStateData(stateName); // Fetch user data for the clicked state
                                }
                            });
                        }
                    }).addTo(map); // Render GeoJSON data on map
                })
                .catch(error => {
                    console.error('Error fetching GeoJSON data:', error);
                });
        }
    }, []); // Render map only once when component mounts

    const fetchStateData = async (stateName) => {
        try {
            const key = localStorage.getItem('jwtToken');
            const response = await fetch(`${BASE_URL}/dashboard/user/state-count?state=${stateName}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `Bearer ${key}`,
                },
            });
            const data = await response.json();
            setSelectedState(data.state); // Update selectedState with the user count for the specific state
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    return (
        <div className='row' style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='col-lg-9' style={{ paddingRight: '20px', marginTop: '10px' }}> {/* Added marginTop */}
                <div className='card' style={{ height: '100%' }}>
                    <div className="calorie-consumption">
                        <h4 className='card-header pb-0'>Map</h4>
                        <div className='card-body' style={{ height: '400px' }}>
                            <div ref={mapContainerRef} id="indiaMap" style={{ height: '100%', width: '100%' }}></div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className='col-lg-3'>
                <div className='card' style={{ height: '100%' }}>
                    <div className='card-body'>
                        <h4>{selectedState || ''}</h4> {/* Display the selected state */}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>State</th>
                                    <th>No. of Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersData.map(([state, users], index) => (
                                    <tr key={state} style={{ backgroundColor: index === usersData.length - 1 ? 'lightgray' : 'transparent' }}>
                                        <td>{state}</td>
                                        <td>{state === 'No Access' ? <span style={{ color: 'orange', fontWeight: 'bold' }}>{users}</span> : <span style={{ fontWeight: 'bold' }}>{users}</span>}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;
