
// // import React, { useState } from "react";
// // import ErrorIcon from '@mui/icons-material/Error';
// // import DashboardIcon from "@mui/icons-material/Dashboard";
// // import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// // import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
// // import BookIcon from '@mui/icons-material/Book';
// // import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
// // import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
// // import LocalDiningIcon from '@mui/icons-material/LocalDining';

// // import "./sidebar.scss"; // Import the CSS file
// // import MenuIcon from '@mui/icons-material/Menu';
// // import { Link } from "react-router-dom";

// // const Sidebar = () => {
// //   const [isOpen, setIsOpen] = useState(false);

// //   const toggleSidebar = () => {
// //     setIsOpen(!isOpen);
// //   };

// //   return (
// //     <>
// //      <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
// //      <MenuIcon style={{ color: '#000' }} />
// // </button>
// //       <div className={`sidebar ${isOpen ? "show" : ""}`}>
// //         <div className="top">
// //           <Link to="/" style={{ textDecoration: "none", fontSize: '18px' }}>
// //             <span className="logo">NIN Admin Dashboard</span>
// //           </Link>
// //         </div>
// //         <hr />
// //         <div className="bottom">
// //           <ul>
// //             <Link to="/home" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <DashboardIcon className="icon" />
// //                 <span>Dashboard</span>
// //               </li>
// //             </Link>
// //             <Link to="/health" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <HealthAndSafetyIcon className="icon" />
// //                 <span>Health Status</span>
// //               </li>
// //             </Link>
// //             <Link to="/users" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <Person3OutlinedIcon className="icon" />
// //                 <span>Users Data</span>
// //               </li>
// //             </Link>
// //             <Link to="/bookdata" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <BookIcon className="icon" />
// //                 <span>Book & References</span>
// //               </li>
// //             </Link>
// //             <Link to="/bugs" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <ErrorIcon className="icon" />
// //                 <span>Bug Reported</span>
// //               </li>
// //             </Link>
// //             <Link to="/contact" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <ContactEmergencyIcon className="icon" />
// //                 <span>Feedback & Contact us</span>
// //               </li>
// //             </Link>
           
// //             <Link to="/rawdata" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <EmojiFoodBeverageIcon className="icon" />
// //                 <span>Raw Food</span>
// //               </li>
// //             </Link>
// //             <Link to="/recipes" style={{ textDecoration: "none" }}>
// //               <li>
// //                 <LocalDiningIcon className="icon" />
// //                 <span>Recipes</span>
// //               </li>
// //             </Link>
// //           </ul>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };

// // export default Sidebar;
// import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
// import ErrorIcon from '@mui/icons-material/Error';
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
// import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
// import BookIcon from '@mui/icons-material/Book';
// import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
// import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
// import LocalDiningIcon from '@mui/icons-material/LocalDining';

// import "./sidebar.scss"; // Import the CSS file
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from "react-router-dom";

// const Sidebar = () => {
//   const location = useLocation();
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <>
//      <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
//      <MenuIcon style={{ color: '#000' }} />
// </button>
//       <div className={`sidebar ${isOpen ? "show" : ""}`}>
//         <div className="top">
//           <Link to="/" style={{ textDecoration: "none", fontSize: '18px' }}>
//             <span className="logo">NIN Admin Dashboard</span>
//           </Link>
//         </div>
//         <hr />
//         <div className="bottom">
//           <ul>
//             <Link to="/home" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/home" ? "active" : ""}>
//                 <DashboardIcon className="icon" />
//                 <span>Dashboard</span>
//               </li>
//             </Link>
//             <Link to="/health" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/health" ? "active" : ""}>
//                 <HealthAndSafetyIcon className="icon" />
//                 <span>Health Status</span>
//               </li>
//             </Link>
//             <Link to="/users" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/users" ? "active" : ""}>
//                 <Person3OutlinedIcon className="icon" />
//                 <span>Users Data</span>
//               </li>
//             </Link>
//             <Link to="/bookdata" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/bookdata" ? "active" : ""}>
//                 <BookIcon className="icon" />
//                 <span>Book & References</span>
//               </li>
//             </Link>
//             <Link to="/bugs" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/bugs" ? "active" : ""}>
//                 <ErrorIcon className="icon" />
//                 <span>Bug Reported</span>
//               </li>
//             </Link>
//             <Link to="/contact" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/contact" ? "active" : ""}>
//                 <ContactEmergencyIcon className="icon" />
//                 <span>Feedback & Contact us</span>
//               </li>
//             </Link>
           
//             <Link to="/rawdata" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/rawdata" ? "active" : ""}>
//                 <EmojiFoodBeverageIcon className="icon" />
//                 <span>Raw Food</span>
//               </li>
//             </Link>
//             <Link to="/recipes" style={{ textDecoration: "none" }}>
//               <li className={location.pathname === "/recipes" ? "active" : ""}>
//                 <LocalDiningIcon className="icon" />
//                 <span>Recipes</span>
//               </li>
//             </Link>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Sidebar;
import React, { useState } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import DashboardIcon from "@mui/icons-material/Dashboard";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import BookIcon from '@mui/icons-material/Book';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

import "./sidebar.scss"; // Import the CSS file
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
     <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
     <MenuIcon style={{ color: '#000' }} />
</button>
      <div className={`sidebar ${isOpen ? "show" : ""}`}>
        <div className="top">
          <Link to="/" style={{ textDecoration: "none", fontSize: '18px' }}>
            <span className="logo">NIN Admin Dashboard</span>
          </Link>
        </div>
        <hr />
        <div className="bottom">
          <ul>
            <Link to="/home" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/home" ? "active" : ""}>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </li>
            </Link>
            <Link to="/health" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/health" ? "active" : ""}>
                <HealthAndSafetyIcon className="icon" />
                <span>Health Status</span>
              </li>
            </Link>
            <Link to="/users" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/users" ? "active" : ""}>
                <Person3OutlinedIcon className="icon" />
                <span>Users Data</span>
              </li>
            </Link>
            <Link to="/bookdata" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/bookdata" ? "active" : ""}>
                <BookIcon className="icon" />
                <span>Book Details</span>
              </li>
            </Link>
            <Link to="/bugs" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/bugs" ? "active" : ""}>
                <ErrorIcon className="icon" />
                <span>Bug Reported</span>
              </li>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/contact" ? "active" : ""}>
                <ContactEmergencyIcon className="icon" />
                <span>Feedback</span>
              </li>
            </Link>
           
            <Link to="/rawdata" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/rawdata" ? "active" : ""}>
                <EmojiFoodBeverageIcon className="icon" />
                <span>Raw Food</span>
              </li>
            </Link>
            <Link to="/recipes" style={{ textDecoration: "none" }}>
              <li className={location.pathname === "/recipes" ? "active" : ""}>
                <LocalDiningIcon className="icon" />
                <span>Recipes</span>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
