
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import { BASE_URL } from '../../components/config/apiConfig';
import RedirectComponent from "../../components/redirectcomponent/RedirectComponent";
import "./user.scss"; // Import external CSS file

const User = () => {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10); // Define number of users per page
    const [totalUsers, setTotalUsers] = useState(0); // Total number of users
    const [loading, setLoading] = useState(false); // Loading indicator
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchData();
    }, [currentPage, location.search, searchQuery]); // Fetch data when currentPage, location.search, or searchQuery changes

    const fetchData = async () => {
        setLoading(true);
        const key = localStorage.getItem('jwtToken');
        const urlParams = new URLSearchParams(location.search);
        const page = parseInt(urlParams.get('page')) || 1;

        try {
            const response = await fetch(`${BASE_URL}/dashboard/get-all-users-detail?page=${page - 1}&size=${usersPerPage}&searchQuery=${searchQuery}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Auth': `Bearer ${key}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const data = await response.json();
            let filteredUsers = data.userList;

            // Filter the users based on the search query
            if (searchQuery) {
                // Create a regular expression to match the search query in sequence
                const regex = new RegExp(`\\b${searchQuery}`, 'i');
                filteredUsers = data.userList.filter(user => {
                    return regex.test(user.email);
                });
            }

            setUsers(filteredUsers);
            setTotalUsers(filteredUsers.length);
            setCurrentPage(page);
        } catch (error) {
            console.error('Error:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const checkStatus = (userId, e) => {
        if (!e.target.matches('button')) {
            return;
        }
        navigate(`/statuspage/${userId}`);
    };

    const paginate = (pageNumber) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('page', pageNumber);
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };

    return (
        <div className="user-container">
            <RedirectComponent />
            <div className="scrollableSidebar">
                <Sidebar />
            </div>
            <div className="main-content">
                <h1 className="user-list-title">User List</h1>
                <div className="faded-line" />
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search by Email"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <p>Total Users: {totalUsers}</p>
                </div>
                {loading ? (
                    <div className="loading-indicator">
                        <h2>Loading<span className="blink">...</span></h2>
                    </div>
                ) : (
                    <table className="user-table">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                                <th>Device Type</th>
                                <th>Local Date</th>
                                <th>State</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.userId} className="user-row" onClick={(e) => checkStatus(user.userId, e)}>
                                    <td>{user.userName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.mobileNo}</td>
                                    <td>{user.deviceType}</td>
                                    <td>{user.localDate.join('/')}</td>
                                    <td>{user.state}</td>
                                    <td>
                                        <button
                                            className="button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                checkStatus(user.userId, e);
                                            }}
                                        >
                                            Check Status
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <div className="pagination-container">
                    {/* Pagination controls */}
                    {/* Previous button */}
                    <button
                        style={{ margin: '0 5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer', background: currentPage === 1 ? '#f2f2f2' : 'white', color: currentPage === 1 ? '#999' : '#007bff' }}
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                    >
                        {'<'}
                    </button>
                    {Array.from({ length: Math.min(Math.ceil(totalUsers / usersPerPage), 3) }, (_, i) => {
                        const pageNumber = currentPage + i;
                        if (pageNumber <= Math.ceil(totalUsers / usersPerPage)) {
                            return (
                                <button
                                    key={i}
                                    style={{ margin: '0 5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer', background: currentPage === pageNumber ? '#007bff' : 'white', color: currentPage === pageNumber ? 'white' : '#007bff' }}
                                    onClick={() => paginate(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            );
                        }
                        return null;
                    })}
                    {/* Next button */}
                    <button
                        style={{ margin: '0 5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '4px', cursor: 'pointer', background: currentPage === Math.min(Math.ceil(totalUsers / usersPerPage), 3) ? '#f2f2f2' : 'white', color: currentPage === Math.min(Math.ceil(totalUsers / usersPerPage), 3) ? '#999' : '#007bff' }}
                        disabled={currentPage === Math.min(Math.ceil(totalUsers / usersPerPage), 3)}
                        onClick={() => paginate(currentPage + 1)}
                    >
                        {'>'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default User;
