



// import React, { useState, useEffect } from 'react';
// import Navbar from "../../components/navbar/Navbar";
// import Sidebar from "../../components/sidebar/Sidebar";
// import { Link } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SaveIcon from '@mui/icons-material/Save';
// import CancelIcon from '@mui/icons-material/Cancel';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import BASE_URL from '../../components/config/apiConfig';
// const FoodNutrientsComponent = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [editableRowIndex, setEditableRowIndex] = useState(null);
//   const [filteredData, setFilteredData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${BASE_URL}/dashboard/get-all-row-food-nutrients`);
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const jsonData = await response.json();
//         setData(jsonData);
//         setLoading(false);
//       } catch (error) {
//         setError(error.message);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   useEffect(() => {
//     setFilteredData(
//       data.filter(item => (
//         item.food.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         item.foodCode.toLowerCase().includes(searchQuery.toLowerCase())
//       ))
//     );
//   }, [data, searchQuery]);

//   const handleEdit = (index) => {
//     setEditableRowIndex(index);
//   };

//   const handleUpdate = async (index) => {
//     try {
//       const response = await fetch(`${BASE_URL}/dashboard/update-row-food-nutrients/${filteredData[index].foodId}`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(filteredData[index])
//       });

//       if (!response.ok) {
//         throw new Error('Failed to update data');
//       }

//       toast.success('Data updated successfully');
//       setEditableRowIndex(null);
//     } catch (error) {
//       console.error('Error updating data:', error.message);
//       toast.error('Failed to update data');
//     }
//   };

//   const handleCancelEdit = () => {
//     setEditableRowIndex(null);
//   };

//   const handleInputChange = (event, fieldName, index) => {
//     const newData = [...filteredData];
//     newData[index][fieldName] = event.target.value;
//     setFilteredData(newData);
//   };

//   const handleDelete = async (foodId) => {
//     const isConfirmed = window.confirm("Are you sure you want to delete this item?");
//     if (!isConfirmed) {
//       return;
//     }

//     try {
//       const response = await fetch(`${BASE_URL}/dashboard/delete-row-food-nutrients/${foodId}`, {
//         method: 'DELETE',
//       });

//       if (!response.ok) {
//         throw new Error('Failed to delete data');
//       }

//       setData(prevData => prevData.filter(item => item.foodId !== foodId));
//       toast.success('Data deleted successfully');
//     } catch (error) {
//       console.error('Error deleting data:', error.message);
//       toast.error('Failed to delete data');
//     }
//   };
//   if (loading) {
//     return (
//       <div style={{ textAlign: 'center', marginTop: '20px' }}>
//         <h2 style={{ color: 'blue' }}>Loading<span style={{ animation: 'blink 1s infinite' }}>...</span></h2>
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
    
//     <div style={{ position: 'relative' }}>
//          <div className="scrollableSidebar">
//                 <Sidebar />
//             </div>

//       <div style={{ marginLeft: '280px', textAlign: 'center' }}>
//   <h1>Food Nutrients</h1>
//         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
//           {/* Button for adding data */}
//           <Link to="/addrawdata">
//             <button style={{ marginRight: '10px', height: '30px', width: '100px', backgroundColor: 'rgb(76, 175, 80)', color: 'white' }}>Add Data</button>
//           </Link>
//           {/* Search bar */}
//           <input
//             type="text"
//             placeholder="Search by food or food code..."
//             style={{ flex: 1, height: '30px', marginLeft: '10px', paddingLeft: '10px', border: '1px solid #ccc' }}
//             value={searchQuery || ''}
//             onChange={e => setSearchQuery(e.target.value)}
//           />
//         </div>

//         <div className="table-container" style={tableContainerStyle}>
//           <table className="food-table" style={foodTableStyle}>
//             <thead>
//               <tr>
//                 <th style={tableHeaderStyle}>FoodId</th>
//                 <th style={tableHeaderStyle}>Food</th>
//                 <th style={tableHeaderStyle}>FoodCode</th>
//                 <th style={tableHeaderStyle}>Category</th>
//                 <th style={tableHeaderStyle}>Energy</th>
//                 <th style={tableHeaderStyle}>Protein</th>
//                 <th style={tableHeaderStyle}>Total Fat</th>
//                 <th style={tableHeaderStyle}>Total Dietary Fibre</th>
//                 <th style={tableHeaderStyle}>Carbohydrate</th>
//                 <th style={tableHeaderStyle}>Thiamine (B1)</th>
//                 <th style={tableHeaderStyle}>Riboflavin (B2)</th>
//                 <th style={tableHeaderStyle}>Niacin (B3)</th>
//                 <th style={tableHeaderStyle}>Vitamin B6</th>
//                 <th style={tableHeaderStyle}>Total Folates (B9)</th>
//                 <th style={tableHeaderStyle}>Vitamin C</th>
//                 <th style={tableHeaderStyle}>Vitamin A</th>
//                 <th style={tableHeaderStyle}>Iron</th>
//                 <th style={tableHeaderStyle}>Zinc</th>
//                 <th style={tableHeaderStyle}>Sodium</th>
//                 <th style={tableHeaderStyle}>Calcium</th>
//                 <th style={tableHeaderStyle}>Magnesium</th>
//                 <th style={tableHeaderStyle}>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredData.map((item, index) => (
//                 <tr key={index}>
//                   <td style={tableCellStyle}>{item.foodId}</td>
//                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.food} onChange={(event) => handleInputChange(event, 'food', index)} /> : item.food}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.foodCode} onChange={(event) => handleInputChange(event, 'foodCode', index)} /> : item.foodCode}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.category} onChange={(event) => handleInputChange(event, 'category', index)} /> : item.category}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.energy} onChange={(event) => handleInputChange(event, 'energy', index)} /> : item.energy}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.protein} onChange={(event) => handleInputChange(event, 'protein', index)} /> : item.protein}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalFat} onChange={(event) => handleInputChange(event, 'totalFat', index)} /> : item.totalFat}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalDietaryFibre} onChange={(event) => handleInputChange(event, 'totalDietaryFibre', index)} /> : item.totalDietaryFibre}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.carbohydrate} onChange={(event) => handleInputChange(event, 'carbohydrate', index)} /> : item.carbohydrate}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.thiamineB1} onChange={(event) => handleInputChange(event, 'thiamineB1', index)} /> : item.thiamineB1}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.riboflavinB2} onChange={(event) => handleInputChange(event, 'riboflavinB2', index)} /> : item.riboflavinB2}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.niacinB3} onChange={(event) => handleInputChange(event, 'niacinB3', index)} /> : item.niacinB3}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitB6} onChange={(event) => handleInputChange(event, 'vitB6', index)} /> : item.vitB6}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalFolatesB9} onChange={(event) => handleInputChange(event, 'totalFolatesB9', index)} /> : item.totalFolatesB9}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitC} onChange={(event) => handleInputChange(event, 'vitC', index)} /> : item.vitC}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitA} onChange={(event) => handleInputChange(event, 'vitA', index)} /> : item.vitA}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.iron} onChange={(event) => handleInputChange(event, 'iron', index)} /> : item.iron}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.zinc} onChange={(event) => handleInputChange(event, 'zinc', index)} /> : item.zinc}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.sodium} onChange={(event) => handleInputChange(event, 'sodium', index)} /> : item.sodium}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.calcium} onChange={(event) => handleInputChange(event, 'calcium', index)} /> : item.calcium}</td>
//                   <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.magnesium} onChange={(event) => handleInputChange(event, 'magnesium', index)} /> : item.magnesium}</td>
//                   <td style={tableCellStyle}>
//                     {editableRowIndex === index ? (
//                       <>
//                          <SaveIcon style={{ cursor: 'pointer', marginRight: '5px', color: 'rgb(76, 175, 80)' }} onClick={() => handleUpdate(index)} />
//     <CancelIcon style={{ cursor: 'pointer', color: 'red' }} onClick={handleCancelEdit} />
//                       </>
//                     ) : (
//                       <EditIcon style={{ cursor: 'pointer', marginRight: '5px', color: 'rgb(76, 175, 80)' }} onClick={() => handleEdit(index)} />
//                     )}
//                     <DeleteIcon style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(item.foodId)} />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
    
//   );
// };

// export default FoodNutrientsComponent;

// // Internal CSS styles

// const tableContainerStyle = {
//   overflowX: 'auto',
// };

// const foodTableStyle = {
//   width: '100%',
//   borderCollapse: 'collapse',
// };

// const tableHeaderStyle = {
//   backgroundColor: '#f2f2f2',
//   border: '1px solid #dddddd',
//   padding: '8px',
//   textAlign: 'left',
// };

// const tableCellStyle = {
//   border: '1px solid #dddddd',
//   padding: '8px',
//   textAlign: 'left',
// };

import React, { useState, useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BASE_URL from '../../components/config/apiConfig';
import "./rowdata.scss"; // Import external CSS fi


const FoodNutrientsComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/dashboard/get-all-row-food-nutrients`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter(item => (
        item.food.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.foodCode.toLowerCase().includes(searchQuery.toLowerCase())
      ))
    );
  }, [data, searchQuery]);

  const handleEdit = (index) => {
    setEditableRowIndex(index);
  };

  const handleUpdate = async (index) => {
    try {
      const response = await fetch(`${BASE_URL}/dashboard/update-row-food-nutrients/${filteredData[index].foodId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(filteredData[index])
      });

      if (!response.ok) {
        throw new Error('Failed to update data');
      }

      toast.success('Data updated successfully');
      setEditableRowIndex(null);
    } catch (error) {
      console.error('Error updating data:', error.message);
      toast.error('Failed to update data');
    }
  };

  const handleCancelEdit = () => {
    setEditableRowIndex(null);
  };

  const handleInputChange = (event, fieldName, index) => {
    const newData = [...filteredData];
    newData[index][fieldName] = event.target.value;
    setFilteredData(newData);
  };

  const handleDelete = async (foodId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this item?");
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/dashboard/delete-row-food-nutrients/${foodId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete data');
      }

      setData(prevData => prevData.filter(item => item.foodId !== foodId));
      toast.success('Data deleted successfully');
    } catch (error) {
      console.error('Error deleting data:', error.message);
      toast.error('Failed to delete data');
    }
  };
  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h2 style={{ color: 'blue' }}>Loading<span style={{ animation: 'blink 1s infinite' }}>...</span></h2>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    
    <div style={{ position: 'relative' }}>
      <div className="scrollableSidebar">
        <Sidebar />
      </div>

      <div className="content-after-sidebar" >
      <h1 className="recipe-title">Food Nutrients</h1>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          {/* Button for adding data */}
          <Link to="/addrawdata">
            <button style={{ marginRight: '10px', height: '30px', width: '100px', backgroundColor: 'rgb(76, 175, 80)', color: 'white' }}>Add Data</button>
          </Link>
          {/* Search bar */}
          <input
            type="text"
            placeholder="Search by food or food code..."
            style={{ flex: 1, height: '30px', marginLeft: '10px', paddingLeft: '10px', border: '1px solid #ccc' }}
            value={searchQuery || ''}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="table-container" style={tableContainerStyle}>
          <table className="food-table" style={foodTableStyle}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>FoodId</th>
                <th style={tableHeaderStyle}>Food</th>
                <th style={tableHeaderStyle}>FoodCode</th>
                <th style={tableHeaderStyle}>Category</th>
                <th style={tableHeaderStyle}>Energy</th>
                <th style={tableHeaderStyle}>Protein</th>
                <th style={tableHeaderStyle}>Total Fat</th>
                <th style={tableHeaderStyle}>Total Dietary Fibre</th>
                <th style={tableHeaderStyle}>Carbohydrate</th>
                <th style={tableHeaderStyle}>Thiamine (B1)</th>
                <th style={tableHeaderStyle}>Riboflavin (B2)</th>
                <th style={tableHeaderStyle}>Niacin (B3)</th>
                <th style={tableHeaderStyle}>Vitamin B6</th>
                <th style={tableHeaderStyle}>Total Folates (B9)</th>
                <th style={tableHeaderStyle}>Vitamin C</th>
                <th style={tableHeaderStyle}>Vitamin A</th>
                <th style={tableHeaderStyle}>Iron</th>
                <th style={tableHeaderStyle}>Zinc</th>
                <th style={tableHeaderStyle}>Sodium</th>
                <th style={tableHeaderStyle}>Calcium</th>
                <th style={tableHeaderStyle}>Magnesium</th>
                <th style={tableHeaderStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{item.foodId}</td>
                 <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.food} onChange={(event) => handleInputChange(event, 'food', index)} /> : item.food}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.foodCode} onChange={(event) => handleInputChange(event, 'foodCode', index)} /> : item.foodCode}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.category} onChange={(event) => handleInputChange(event, 'category', index)} /> : item.category}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.energy} onChange={(event) => handleInputChange(event, 'energy', index)} /> : item.energy}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.protein} onChange={(event) => handleInputChange(event, 'protein', index)} /> : item.protein}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalFat} onChange={(event) => handleInputChange(event, 'totalFat', index)} /> : item.totalFat}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalDietaryFibre} onChange={(event) => handleInputChange(event, 'totalDietaryFibre', index)} /> : item.totalDietaryFibre}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.carbohydrate} onChange={(event) => handleInputChange(event, 'carbohydrate', index)} /> : item.carbohydrate}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.thiamineB1} onChange={(event) => handleInputChange(event, 'thiamineB1', index)} /> : item.thiamineB1}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.riboflavinB2} onChange={(event) => handleInputChange(event, 'riboflavinB2', index)} /> : item.riboflavinB2}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.niacinB3} onChange={(event) => handleInputChange(event, 'niacinB3', index)} /> : item.niacinB3}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitB6} onChange={(event) => handleInputChange(event, 'vitB6', index)} /> : item.vitB6}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.totalFolatesB9} onChange={(event) => handleInputChange(event, 'totalFolatesB9', index)} /> : item.totalFolatesB9}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitC} onChange={(event) => handleInputChange(event, 'vitC', index)} /> : item.vitC}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.vitA} onChange={(event) => handleInputChange(event, 'vitA', index)} /> : item.vitA}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.iron} onChange={(event) => handleInputChange(event, 'iron', index)} /> : item.iron}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.zinc} onChange={(event) => handleInputChange(event, 'zinc', index)} /> : item.zinc}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.sodium} onChange={(event) => handleInputChange(event, 'sodium', index)} /> : item.sodium}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.calcium} onChange={(event) => handleInputChange(event, 'calcium', index)} /> : item.calcium}</td>
                  <td style={tableCellStyle}>{editableRowIndex === index ? <input type="text" value={item.magnesium} onChange={(event) => handleInputChange(event, 'magnesium', index)} /> : item.magnesium}</td>
                  <td style={tableCellStyle}>
                    {editableRowIndex === index ? (
                      <>
                         <SaveIcon style={{ cursor: 'pointer', marginRight: '5px', color: 'rgb(76, 175, 80)' }} onClick={() => handleUpdate(index)} />
    <CancelIcon style={{ cursor: 'pointer', color: 'red' }} onClick={handleCancelEdit} />
                      </>
                    ) : (
                      <EditIcon style={{ cursor: 'pointer', marginRight: '5px', color: 'rgb(76, 175, 80)' }} onClick={() => handleEdit(index)} />
                    )}
                    <DeleteIcon style={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDelete(item.foodId)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  );
};

export default FoodNutrientsComponent;

// Internal CSS styles

const tableContainerStyle = {
  overflowX: 'auto',
};

const foodTableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
};

const tableHeaderStyle = {
  backgroundColor: '#f2f2f2',
  border: '1px solid #dddddd',
  padding: '8px',
  textAlign: 'left',
};

const tableCellStyle = {
  border: '1px solid #dddddd',
  padding: '8px',
  textAlign: 'left',
};
